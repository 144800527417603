export enum BetType {
  UNKNOWN = 'unknown',

  // evolution
  ROU_00GREEN = 'ROU_00Green',
  ROU_0GREEN = 'ROU_0Green',
  ROU_SPLITBET0AND00 = 'ROU_SplitBet0And00',
  ROU_1RED = 'ROU_1Red',
  ROU_2BLACK = 'ROU_2Black',
  ROU_3RED = 'ROU_3Red',
  ROU_4BLACK = 'ROU_4Black',
  ROU_5RED = 'ROU_5Red',
  ROU_6BLACK = 'ROU_6Black',
  ROU_7RED = 'ROU_7Red',
  ROU_8BLACK = 'ROU_8Black',
  ROU_9RED = 'ROU_9Red',
  ROU_10BLACK = 'ROU_10Black',
  ROU_11BLACK = 'ROU_11Black',
  ROU_12RED = 'ROU_12Red',
  ROU_13BLACK = 'ROU_13Black',
  ROU_14RED = 'ROU_14Red',
  ROU_15BLACK = 'ROU_15Black',
  ROU_16RED = 'ROU_16Red',
  ROU_17BLACK = 'ROU_17Black',
  ROU_18RED = 'ROU_18Red',
  ROU_19RED = 'ROU_19Red',
  ROU_20BLACK = 'ROU_20Black',
  ROU_21RED = 'ROU_21Red',
  ROU_22BLACK = 'ROU_22Black',
  ROU_23RED = 'ROU_23Red',
  ROU_24BLACK = 'ROU_24Black',
  ROU_25RED = 'ROU_25Red',
  ROU_26BLACK = 'ROU_26Black',
  ROU_27RED = 'ROU_27Red',
  ROU_28BLACK = 'ROU_28Black',
  ROU_29BLACK = 'ROU_29Black',
  ROU_30RED = 'ROU_30Red',
  ROU_31BLACK = 'ROU_31Black',
  ROU_32RED = 'ROU_32Red',
  ROU_33BLACK = 'ROU_33Black',
  ROU_34RED = 'ROU_34Red',
  ROU_35BLACK = 'ROU_35Black',
  ROU_36RED = 'ROU_36Red',
  ROU_COLUMNBET14ETC = 'ROU_ColumnBet14Etc',
  ROU_COLUMNBET25ETC = 'ROU_ColumnBet25Etc',
  ROU_COLUMNBET36ETC = 'ROU_ColumnBet36Etc',
  ROU_DOZENBET112 = 'ROU_DozenBet112',
  ROU_DOZENBET1324 = 'ROU_DozenBet1324',
  ROU_DOZENBET2536 = 'ROU_DozenBet2536',
  ROU_118 = 'ROU_118',
  ROU_EVEN = 'ROU_Even',
  ROU_RED = 'ROU_Red',
  ROU_BLACK = 'ROU_Black',
  ROU_ODD = 'ROU_Odd',
  ROU_1936 = 'ROU_1936',
  ROU_SPLITBET1AND2 = 'ROU_SplitBet1And2',
  ROU_SPLITBET2AND3 = 'ROU_SplitBet2And3',
  ROU_SPLITBET4AND5 = 'ROU_SplitBet4And5',
  ROU_SPLITBET5AND6 = 'ROU_SplitBet5And6',
  ROU_SPLITBET7AND8 = 'ROU_SplitBet7And8',
  ROU_SPLITBET8AND9 = 'ROU_SplitBet8And9',
  ROU_SPLITBET10AND11 = 'ROU_SplitBet10And11',
  ROU_SPLITBET11AND12 = 'ROU_SplitBet11And12',
  ROU_SPLITBET13AND14 = 'ROU_SplitBet13And14',
  ROU_SPLITBET14AND15 = 'ROU_SplitBet14And15',
  ROU_SPLITBET16AND17 = 'ROU_SplitBet16And17',
  ROU_SPLITBET17AND18 = 'ROU_SplitBet17And18',
  ROU_SPLITBET19AND20 = 'ROU_SplitBet19And20',
  ROU_SPLITBET20AND21 = 'ROU_SplitBet20And21',
  ROU_SPLITBET22AND23 = 'ROU_SplitBet22And23',
  ROU_SPLITBET23AND24 = 'ROU_SplitBet23And24',
  ROU_SPLITBET25AND26 = 'ROU_SplitBet25And26',
  ROU_SPLITBET26AND27 = 'ROU_SplitBet26And27',
  ROU_SPLITBET28AND29 = 'ROU_SplitBet28And29',
  ROU_SPLITBET29AND30 = 'ROU_SplitBet29And30',
  ROU_SPLITBET31AND32 = 'ROU_SplitBet31And32',
  ROU_SPLITBET32AND33 = 'ROU_SplitBet32And33',
  ROU_SPLITBET34AND35 = 'ROU_SplitBet34And35',
  ROU_SPLITBET35AND36 = 'ROU_SplitBet35And36',
  ROU_SPLITBET1AND4 = 'ROU_SplitBet1And4',
  ROU_SPLITBET2AND5 = 'ROU_SplitBet2And5',
  ROU_SPLITBET3AND6 = 'ROU_SplitBet3And6',
  ROU_SPLITBET4AND7 = 'ROU_SplitBet4And7',
  ROU_SPLITBET5AND8 = 'ROU_SplitBet5And8',
  ROU_SPLITBET6AND9 = 'ROU_SplitBet6And9',
  ROU_SPLITBET7AND10 = 'ROU_SplitBet7And10',
  ROU_SPLITBET8AND11 = 'ROU_SplitBet8And11',
  ROU_SPLITBET9AND12 = 'ROU_SplitBet9And12',
  ROU_SPLITBET10AND13 = 'ROU_SplitBet10And13',
  ROU_SPLITBET11AND14 = 'ROU_SplitBet11And14',
  ROU_SPLITBET12AND15 = 'ROU_SplitBet12And15',
  ROU_SPLITBET13AND16 = 'ROU_SplitBet13And16',
  ROU_SPLITBET14AND17 = 'ROU_SplitBet14And17',
  ROU_SPLITBET15AND18 = 'ROU_SplitBet15And18',
  ROU_SPLITBET16AND19 = 'ROU_SplitBet16And19',
  ROU_SPLITBET17AND20 = 'ROU_SplitBet17And20',
  ROU_SPLITBET18AND21 = 'ROU_SplitBet18And21',
  ROU_SPLITBET19AND22 = 'ROU_SplitBet19And22',
  ROU_SPLITBET20AND23 = 'ROU_SplitBet20And23',
  ROU_SPLITBET21AND24 = 'ROU_SplitBet21And24',
  ROU_SPLITBET22AND25 = 'ROU_SplitBet22And25',
  ROU_SPLITBET23AND26 = 'ROU_SplitBet23And26',
  ROU_SPLITBET24AND27 = 'ROU_SplitBet24And27',
  ROU_SPLITBET25AND28 = 'ROU_SplitBet25And28',
  ROU_SPLITBET26AND29 = 'ROU_SplitBet26And29',
  ROU_SPLITBET27AND30 = 'ROU_SplitBet27And30',
  ROU_SPLITBET28AND31 = 'ROU_SplitBet28And31',
  ROU_SPLITBET29AND32 = 'ROU_SplitBet29And32',
  ROU_SPLITBET30AND33 = 'ROU_SplitBet30And33',
  ROU_SPLITBET31AND34 = 'ROU_SplitBet31And34',
  ROU_SPLITBET32AND35 = 'ROU_SplitBet32And35',
  ROU_SPLITBET33AND36 = 'ROU_SplitBet33And36',
  ROU_SQUAREBET124AND5 = 'ROU_SquareBet124And5',
  ROU_SQUAREBET235AND6 = 'ROU_SquareBet235And6',
  ROU_SQUAREBET457AND8 = 'ROU_SquareBet457And8',
  ROU_SQUAREBET568AND9 = 'ROU_SquareBet568And9',
  ROU_SQUAREBET7810AND11 = 'ROU_SquareBet7810And11',
  ROU_SQUAREBET8911AND12 = 'ROU_SquareBet8911And12',
  ROU_SQUAREBET101113AND14 = 'ROU_SquareBet101113And14',
  ROU_SQUAREBET111214AND15 = 'ROU_SquareBet111214And15',
  ROU_SQUAREBET131416AND17 = 'ROU_SquareBet131416And17',
  ROU_SQUAREBET141517AND18 = 'ROU_SquareBet141517And18',
  ROU_SQUAREBET161719AND20 = 'ROU_SquareBet161719And20',
  ROU_SQUAREBET171820AND21 = 'ROU_SquareBet171820And21',
  ROU_SQUAREBET192022AND23 = 'ROU_SquareBet192022And23',
  ROU_SQUAREBET202123AND24 = 'ROU_SquareBet202123And24',
  ROU_SQUAREBET222325AND26 = 'ROU_SquareBet222325And26',
  ROU_SQUAREBET232426AND27 = 'ROU_SquareBet232426And27',
  ROU_SQUAREBET252628AND29 = 'ROU_SquareBet252628And29',
  ROU_SQUAREBET262729AND30 = 'ROU_SquareBet262729And30',
  ROU_SQUAREBET282931AND32 = 'ROU_SquareBet282931And32',
  ROU_SQUAREBET293032AND33 = 'ROU_SquareBet293032And33',
  ROU_SQUAREBET313234AND35 = 'ROU_SquareBet313234And35',
  ROU_SQUAREBET323335AND36 = 'ROU_SquareBet323335And36',
  ROU_STREETBET12AND3 = 'ROU_StreetBet12And3',
  ROU_STREETBET45AND6 = 'ROU_StreetBet45And6',
  ROU_STREETBET78AND9 = 'ROU_StreetBet78And9',
  ROU_STREETBET1011AND12 = 'ROU_StreetBet1011And12',
  ROU_STREETBET1314AND15 = 'ROU_StreetBet1314And15',
  ROU_STREETBET1617AND18 = 'ROU_StreetBet1617And18',
  ROU_STREETBET1920AND21 = 'ROU_StreetBet1920And21',
  ROU_STREETBET2223AND24 = 'ROU_StreetBet2223And24',
  ROU_STREETBET2526AND27 = 'ROU_StreetBet2526And27',
  ROU_STREETBET2829AND30 = 'ROU_StreetBet2829And30',
  ROU_STREETBET3132AND33 = 'ROU_StreetBet3132And33',
  ROU_STREETBET3435AND36 = 'ROU_StreetBet3435And36',
  ROU_LINEBET12345AND6 = 'ROU_LineBet12345And6',
  ROU_LINEBET45678AND9 = 'ROU_LineBet45678And9',
  ROU_LINEBET7891011AND12 = 'ROU_LineBet7891011And12',
  ROU_LINEBET1011121314AND15 = 'ROU_LineBet1011121314And15',
  ROU_LINEBET1314151617AND18 = 'ROU_LineBet1314151617And18',
  ROU_LINEBET1617181920AND21 = 'ROU_LineBet1617181920And21',
  ROU_LINEBET1920212223AND24 = 'ROU_LineBet1920212223And24',
  ROU_LINEBET2223242526AND27 = 'ROU_LineBet2223242526And27',
  ROU_LINEBET2526272829AND30 = 'ROU_LineBet2526272829And30',
  ROU_LINEBET2829303132AND33 = 'ROU_LineBet2829303132And33',
  ROU_LINEBET3132333435AND36 = 'ROU_LineBet3132333435And36',
  ROU_SPLITBET0AND1 = 'ROU_SplitBet0And1',
  ROU_SPLITBET0AND2 = 'ROU_SplitBet0And2',
  ROU_SPLITBET0AND3 = 'ROU_SplitBet0And3',
  ROU_STREETBET01AND2 = 'ROU_StreetBet01And2',
  ROU_STREETBET02AND3 = 'ROU_StreetBet02And3',
  ROU_SQUAREBET012AND3 = 'ROU_SquareBet012And3',
  ROU_EVEN50RETURNON0 = 'ROU_Even50ReturnOn0',
  ROU_RED50RETURNON0 = 'ROU_Red50ReturnOn0',
  ROU_BLACK50RETURNON0 = 'ROU_Black50ReturnOn0',
  ROU_ODD50RETURNON0 = 'ROU_Odd50ReturnOn0',
  ROU_11850RETURNON0 = 'ROU_11850ReturnOn0',
  ROU_193650RETURNON0 = 'ROU_193650ReturnOn0',
  BJ_PLAYSEAT7 = 'BJ_PlaySeat7',
  BJ_INSURANCESEAT7 = 'BJ_InsuranceSeat7',
  BJ_DOUBLEDOWNSEAT7 = 'BJ_DoubleDownSeat7',
  BJ_SPLITSEAT7 = 'BJ_SplitSeat7',
  BJ_PLAYSEAT6 = 'BJ_PlaySeat6',
  BJ_INSURANCESEAT6 = 'BJ_InsuranceSeat6',
  BJ_DOUBLEDOWNSEAT6 = 'BJ_DoubleDownSeat6',
  BJ_SPLITSEAT6 = 'BJ_SplitSeat6',
  BJ_PLAYSEAT5 = 'BJ_PlaySeat5',
  BJ_INSURANCESEAT5 = 'BJ_InsuranceSeat5',
  BJ_DOUBLEDOWNSEAT5 = 'BJ_DoubleDownSeat5',
  BJ_SPLITSEAT5 = 'BJ_SplitSeat5',
  BJ_PLAYSEAT4 = 'BJ_PlaySeat4',
  BJ_INSURANCESEAT4 = 'BJ_InsuranceSeat4',
  BJ_DOUBLEDOWNSEAT4 = 'BJ_DoubleDownSeat4',
  BJ_SPLITSEAT4 = 'BJ_SplitSeat4',
  BJ_PLAYSEAT3 = 'BJ_PlaySeat3',
  BJ_INSURANCESEAT3 = 'BJ_InsuranceSeat3',
  BJ_DOUBLEDOWNSEAT3 = 'BJ_DoubleDownSeat3',
  BJ_SPLITSEAT3 = 'BJ_SplitSeat3',
  BJ_PLAYSEAT2 = 'BJ_PlaySeat2',
  BJ_INSURANCESEAT2 = 'BJ_InsuranceSeat2',
  BJ_DOUBLEDOWNSEAT2 = 'BJ_DoubleDownSeat2',
  BJ_SPLITSEAT2 = 'BJ_SplitSeat2',
  BJ_PLAYSEAT1 = 'BJ_PlaySeat1',
  BJ_INSURANCESEAT1 = 'BJ_InsuranceSeat1',
  BJ_DOUBLEDOWNSEAT1 = 'BJ_DoubleDownSeat1',
  BJ_SPLITSEAT1 = 'BJ_SplitSeat1',
  BJ_BETBEHINDPLAYSEAT7 = 'BJ_BetBehindPlaySeat7',
  BJ_BETBEHINDINSURANCESEAT7 = 'BJ_BetBehindInsuranceSeat7',
  BJ_BETBEHINDDOUBLEDOWNSEAT7 = 'BJ_BetBehindDoubleDownSeat7',
  BJ_BETBEHINDSPLITSEAT7 = 'BJ_BetBehindSplitSeat7',
  BJ_BETBEHINDPLAYSEAT6 = 'BJ_BetBehindPlaySeat6',
  BJ_BETBEHINDINSURANCESEAT6 = 'BJ_BetBehindInsuranceSeat6',
  BJ_BETBEHINDDOUBLEDOWNSEAT6 = 'BJ_BetBehindDoubleDownSeat6',
  BJ_BETBEHINDSPLITSEAT6 = 'BJ_BetBehindSplitSeat6',
  BJ_BETBEHINDPLAYSEAT5 = 'BJ_BetBehindPlaySeat5',
  BJ_BETBEHINDINSURANCESEAT5 = 'BJ_BetBehindInsuranceSeat5',
  BJ_BETBEHINDDOUBLEDOWNSEAT5 = 'BJ_BetBehindDoubleDownSeat5',
  BJ_BETBEHINDSPLITSEAT5 = 'BJ_BetBehindSplitSeat5',
  BJ_BETBEHINDPLAYSEAT4 = 'BJ_BetBehindPlaySeat4',
  BJ_BETBEHINDINSURANCESEAT4 = 'BJ_BetBehindInsuranceSeat4',
  BJ_BETBEHINDDOUBLEDOWNSEAT4 = 'BJ_BetBehindDoubleDownSeat4',
  BJ_BETBEHINDSPLITSEAT4 = 'BJ_BetBehindSplitSeat4',
  BJ_BETBEHINDPLAYSEAT3 = 'BJ_BetBehindPlaySeat3',
  BJ_BETBEHINDINSURANCESEAT3 = 'BJ_BetBehindInsuranceSeat3',
  BJ_BETBEHINDDOUBLEDOWNSEAT3 = 'BJ_BetBehindDoubleDownSeat3',
  BJ_BETBEHINDSPLITSEAT3 = 'BJ_BetBehindSplitSeat3',
  BJ_BETBEHINDPLAYSEAT2 = 'BJ_BetBehindPlaySeat2',
  BJ_BETBEHINDINSURANCESEAT2 = 'BJ_BetBehindInsuranceSeat2',
  BJ_BETBEHINDDOUBLEDOWNSEAT2 = 'BJ_BetBehindDoubleDownSeat2',
  BJ_BETBEHINDSPLITSEAT2 = 'BJ_BetBehindSplitSeat2',
  BJ_BETBEHINDPLAYSEAT1 = 'BJ_BetBehindPlaySeat1',
  BJ_BETBEHINDINSURANCESEAT1 = 'BJ_BetBehindInsuranceSeat1',
  BJ_BETBEHINDDOUBLEDOWNSEAT1 = 'BJ_BetBehindDoubleDownSeat1',
  BJ_BETBEHINDSPLITSEAT1 = 'BJ_BetBehindSplitSeat1',
  BJ_BETBEHINDSPLITDOUBLEDOWNSEAT7 = 'BJ_BetBehindSplitDoubleDownSeat7',
  BJ_BETBEHINDSPLITDOUBLEDOWNSEAT6 = 'BJ_BetBehindSplitDoubleDownSeat6',
  BJ_BETBEHINDSPLITDOUBLEDOWNSEAT5 = 'BJ_BetBehindSplitDoubleDownSeat5',
  BJ_BETBEHINDSPLITDOUBLEDOWNSEAT4 = 'BJ_BetBehindSplitDoubleDownSeat4',
  BJ_BETBEHINDSPLITDOUBLEDOWNSEAT3 = 'BJ_BetBehindSplitDoubleDownSeat3',
  BJ_BETBEHINDSPLITDOUBLEDOWNSEAT2 = 'BJ_BetBehindSplitDoubleDownSeat2',
  BJ_BETBEHINDSPLITDOUBLEDOWNSEAT1 = 'BJ_BetBehindSplitDoubleDownSeat1',
  BJ_SPLITDOUBLEDOWNSEAT7 = 'BJ_SplitDoubleDownSeat7',
  BJ_SPLITDOUBLEDOWNSEAT6 = 'BJ_SplitDoubleDownSeat6',
  BJ_SPLITDOUBLEDOWNSEAT5 = 'BJ_SplitDoubleDownSeat5',
  BJ_SPLITDOUBLEDOWNSEAT4 = 'BJ_SplitDoubleDownSeat4',
  BJ_SPLITDOUBLEDOWNSEAT3 = 'BJ_SplitDoubleDownSeat3',
  BJ_SPLITDOUBLEDOWNSEAT2 = 'BJ_SplitDoubleDownSeat2',
  BJ_SPLITDOUBLEDOWNSEAT1 = 'BJ_SplitDoubleDownSeat1',
  BJ_PERFECTPAIRSEAT7 = 'BJ_PerfectPairSeat7',
  BJ_PERFECTPAIRSEAT6 = 'BJ_PerfectPairSeat6',
  BJ_PERFECTPAIRSEAT5 = 'BJ_PerfectPairSeat5',
  BJ_PERFECTPAIRSEAT4 = 'BJ_PerfectPairSeat4',
  BJ_PERFECTPAIRSEAT3 = 'BJ_PerfectPairSeat3',
  BJ_PERFECTPAIRSEAT2 = 'BJ_PerfectPairSeat2',
  BJ_PERFECTPAIRSEAT1 = 'BJ_PerfectPairSeat1',
  BJ_213SEAT7 = 'BJ_213Seat7',
  BJ_213SEAT6 = 'BJ_213Seat6',
  BJ_213SEAT5 = 'BJ_213Seat5',
  BJ_213SEAT4 = 'BJ_213Seat4',
  BJ_213SEAT3 = 'BJ_213Seat3',
  BJ_213SEAT2 = 'BJ_213Seat2',
  BJ_213SEAT1 = 'BJ_213Seat1',
  BJ_ANYPAIRSEAT7 = 'BJ_AnyPairSeat7',
  BJ_ANYPAIRSEAT6 = 'BJ_AnyPairSeat6',
  BJ_ANYPAIRSEAT5 = 'BJ_AnyPairSeat5',
  BJ_ANYPAIRSEAT4 = 'BJ_AnyPairSeat4',
  BJ_ANYPAIRSEAT3 = 'BJ_AnyPairSeat3',
  BJ_ANYPAIRSEAT2 = 'BJ_AnyPairSeat2',
  BJ_ANYPAIRSEAT1 = 'BJ_AnyPairSeat1',
  SBJ_21_PLUS_3 = 'SBJ_21_plus_3',
  SBJ_ANYPAIR = 'SBJ_AnyPair',
  SBJ_BUSTIT = 'SBJ_BustIt',
  SBJ_DOUBLEDOWN = 'SBJ_DoubleDown',
  SBJ_HOT3 = 'SBJ_Hot3',
  SBJ_INSURANCE = 'SBJ_Insurance',
  SBJ_MAIN = 'SBJ_Main',
  SBJ_SPLIT = 'SBJ_Split',
  SBJ_DOUBLEONFIRST = 'SBJ_DoubleOnFirst',
  SBJ_DOUBLEONSECOND = 'SBJ_DoubleOnSecond',
  FBBJ_21_PLUS_3 = 'FBBJ_21_plus_3',
  FBBJ_ANYPAIR = 'FBBJ_AnyPair',
  FBBJ_BUSTIT = 'FBBJ_BustIt',
  FBBJ_DOUBLEDOWN = 'FBBJ_DoubleDown',
  FBBJ_HOT3 = 'FBBJ_Hot3',
  FBBJ_INSURANCE = 'FBBJ_Insurance',
  FBBJ_MAIN = 'FBBJ_Main',
  FBBJ_SPLIT = 'FBBJ_Split',
  FBBJ_DOUBLEONFIRST = 'FBBJ_DoubleOnFirst',
  FBBJ_DOUBLEONSECOND = 'FBBJ_DoubleOnSecond',
  TC_A = 'TC_A',
  TC_B = 'TC_B',
  TC_X = 'TC_X',
  TC_XX = 'TC_XX',
  BAC_PLAYER = 'BAC_Player',
  BAC_BANKER = 'BAC_Banker',
  BAC_TIE = 'BAC_Tie',
  BAC_PLAYERPAIR = 'BAC_PlayerPair',
  BAC_BANKERPAIR = 'BAC_BankerPair',
  BAC_PLAYERBONUS = 'BAC_PlayerBonus',
  BAC_PERFECTPAIR = 'BAC_PerfectPair',
  BAC_EITHERPAIR = 'BAC_EitherPair',
  BAC_SMALL = 'BAC_Small',
  BAC_BIG = 'BAC_Big',
  BAC_BANKERBONUS = 'BAC_BankerBonus',
  BAC_SUPERSIX = 'BAC_SuperSix',
  HLDM_ANTEBET = 'HLDM_AnteBet',
  HLDM_AABONUSBET = 'HLDM_AABonusBet',
  HLDM_CALLBET = 'HLDM_CallBet',
  HLDM_JACKPOTBET = 'HLDM_JackpotBet',
  CSP_ANTEBET = 'CSP_AnteBet',
  CSP_CALLBET = 'CSP_CallBet',
  CSP_5PLUS1BONUSBET = 'CSP_5plus1BonusBet',
  CSP_JACKPOTBET = 'CSP_JackpotBet',
  TCP_ANTEBET = 'TCP_AnteBet',
  TCP_CALLBET = 'TCP_CallBet',
  TCP_PAIRBONUSBET = 'TCP_PairBonusBet',
  TCP_SIXCARDBONUSBET = 'TCP_SixCardBonusBet',
  UTH_ANTEBET = 'UTH_AnteBet',
  UTH_BLINDBET = 'UTH_BlindBet',
  UTH_RAISEX1BET = 'UTH_RaiseX1Bet',
  UTH_RAISEX2BET = 'UTH_RaiseX2Bet',
  UTH_RAISEX3BET = 'UTH_RaiseX3Bet',
  UTH_RAISEX4BET = 'UTH_RaiseX4Bet',
  UTH_TRIPSBONUSBET = 'UTH_TripsBonusBet',
  ETH_ANTEBET = 'ETH_AnteBet',
  ETH_ANTEPLUSBET = 'ETH_AntePlusBet',
  ETH_RAISEX1BET = 'ETH_RaiseX1Bet',
  ETH_RAISEX2BET = 'ETH_RaiseX2Bet',
  ETH_RAISEX3BET = 'ETH_RaiseX3Bet',
  ETH_RAISEX4BET = 'ETH_RaiseX4Bet',
  ETH_BESTFIVEBET = 'ETH_BestFiveBet',
  MW_ONE = 'MW_One',
  MW_TWO = 'MW_Two',
  MW_FIVE = 'MW_Five',
  MW_TEN = 'MW_Ten',
  MW_TWENTY = 'MW_Twenty',
  MW_FORTY = 'MW_Forty',
  RODZ_0GREEN = 'RoDZ_0Green',
  RODZ_1RED = 'RoDZ_1Red',
  RODZ_2BLACK = 'RoDZ_2Black',
  RODZ_3RED = 'RoDZ_3Red',
  RODZ_4BLACK = 'RoDZ_4Black',
  RODZ_5RED = 'RoDZ_5Red',
  RODZ_6BLACK = 'RoDZ_6Black',
  RODZ_7RED = 'RoDZ_7Red',
  RODZ_8BLACK = 'RoDZ_8Black',
  RODZ_9RED = 'RoDZ_9Red',
  RODZ_00GREEN = 'RoDZ_00Green',
  RODZ_10BLACK = 'RoDZ_10Black',
  RODZ_11BLACK = 'RoDZ_11Black',
  RODZ_12RED = 'RoDZ_12Red',
  RODZ_13BLACK = 'RoDZ_13Black',
  RODZ_14RED = 'RoDZ_14Red',
  RODZ_15BLACK = 'RoDZ_15Black',
  RODZ_16RED = 'RoDZ_16Red',
  RODZ_17BLACK = 'RoDZ_17Black',
  RODZ_18RED = 'RoDZ_18Red',
  RODZ_19RED = 'RoDZ_19Red',
  RODZ_20BLACK = 'RoDZ_20Black',
  RODZ_21RED = 'RoDZ_21Red',
  RODZ_22BLACK = 'RoDZ_22Black',
  RODZ_23RED = 'RoDZ_23Red',
  RODZ_24BLACK = 'RoDZ_24Black',
  RODZ_25RED = 'RoDZ_25Red',
  RODZ_26BLACK = 'RoDZ_26Black',
  RODZ_27RED = 'RoDZ_27Red',
  RODZ_28BLACK = 'RoDZ_28Black',
  RODZ_29BLACK = 'RoDZ_29Black',
  RODZ_30RED = 'RoDZ_30Red',
  RODZ_31BLACK = 'RoDZ_31Black',
  RODZ_32RED = 'RoDZ_32Red',
  RODZ_33BLACK = 'RoDZ_33Black',
  RODZ_34RED = 'RoDZ_34Red',
  RODZ_35BLACK = 'RoDZ_35Black',
  RODZ_36RED = 'RoDZ_36Red',
  RODZ_118 = 'RoDZ_118',
  RODZ_ODD = 'RoDZ_Odd',
  RODZ_RED = 'RoDZ_Red',
  RODZ_SPLITBET0AND1 = 'RoDZ_SplitBet0And1',
  RODZ_SPLITBET0AND2 = 'RoDZ_SplitBet0And2',
  RODZ_SPLITBET0AND00 = 'RoDZ_SplitBet0And00',
  RODZ_SPLITBET1AND2 = 'RoDZ_SplitBet1And2',
  RODZ_SPLITBET1AND4 = 'RoDZ_SplitBet1And4',
  RODZ_SPLITBET2AND3 = 'RoDZ_SplitBet2And3',
  RODZ_SPLITBET2AND5 = 'RoDZ_SplitBet2And5',
  RODZ_SPLITBET3AND6 = 'RoDZ_SplitBet3And6',
  RODZ_SPLITBET4AND5 = 'RoDZ_SplitBet4And5',
  RODZ_SPLITBET4AND7 = 'RoDZ_SplitBet4And7',
  RODZ_SPLITBET5AND6 = 'RoDZ_SplitBet5And6',
  RODZ_SPLITBET5AND8 = 'RoDZ_SplitBet5And8',
  RODZ_SPLITBET6AND9 = 'RoDZ_SplitBet6And9',
  RODZ_SPLITBET7AND8 = 'RoDZ_SplitBet7And8',
  RODZ_SPLITBET7AND10 = 'RoDZ_SplitBet7And10',
  RODZ_SPLITBET8AND9 = 'RoDZ_SplitBet8And9',
  RODZ_SPLITBET8AND11 = 'RoDZ_SplitBet8And11',
  RODZ_SPLITBET9AND12 = 'RoDZ_SplitBet9And12',
  RODZ_EVEN = 'RoDZ_Even',
  RODZ_1936 = 'RoDZ_1936',
  RODZ_SPLITBET00AND2 = 'RoDZ_SplitBet00And2',
  RODZ_SPLITBET00AND3 = 'RoDZ_SplitBet00And3',
  RODZ_SPLITBET10AND11 = 'RoDZ_SplitBet10And11',
  RODZ_SPLITBET10AND13 = 'RoDZ_SplitBet10And13',
  RODZ_SPLITBET11AND12 = 'RoDZ_SplitBet11And12',
  RODZ_SPLITBET11AND14 = 'RoDZ_SplitBet11And14',
  RODZ_SPLITBET12AND15 = 'RoDZ_SplitBet12And15',
  RODZ_SPLITBET13AND14 = 'RoDZ_SplitBet13And14',
  RODZ_SPLITBET13AND16 = 'RoDZ_SplitBet13And16',
  RODZ_SPLITBET14AND15 = 'RoDZ_SplitBet14And15',
  RODZ_SPLITBET14AND17 = 'RoDZ_SplitBet14And17',
  RODZ_SPLITBET15AND18 = 'RoDZ_SplitBet15And18',
  RODZ_SPLITBET16AND17 = 'RoDZ_SplitBet16And17',
  RODZ_SPLITBET16AND19 = 'RoDZ_SplitBet16And19',
  RODZ_SPLITBET17AND18 = 'RoDZ_SplitBet17And18',
  RODZ_SPLITBET17AND20 = 'RoDZ_SplitBet17And20',
  RODZ_SPLITBET18AND21 = 'RoDZ_SplitBet18And21',
  RODZ_SPLITBET19AND20 = 'RoDZ_SplitBet19And20',
  RODZ_SPLITBET19AND22 = 'RoDZ_SplitBet19And22',
  RODZ_SPLITBET20AND21 = 'RoDZ_SplitBet20And21',
  RODZ_SPLITBET20AND23 = 'RoDZ_SplitBet20And23',
  RODZ_SPLITBET21AND24 = 'RoDZ_SplitBet21And24',
  RODZ_SPLITBET22AND23 = 'RoDZ_SplitBet22And23',
  RODZ_SPLITBET22AND25 = 'RoDZ_SplitBet22And25',
  RODZ_SPLITBET23AND24 = 'RoDZ_SplitBet23And24',
  RODZ_SPLITBET23AND26 = 'RoDZ_SplitBet23And26',
  RODZ_SPLITBET24AND27 = 'RoDZ_SplitBet24And27',
  RODZ_SPLITBET25AND26 = 'RoDZ_SplitBet25And26',
  RODZ_SPLITBET25AND28 = 'RoDZ_SplitBet25And28',
  RODZ_SPLITBET26AND27 = 'RoDZ_SplitBet26And27',
  RODZ_SPLITBET26AND29 = 'RoDZ_SplitBet26And29',
  RODZ_SPLITBET27AND30 = 'RoDZ_SplitBet27And30',
  RODZ_SPLITBET28AND29 = 'RoDZ_SplitBet28And29',
  RODZ_SPLITBET28AND31 = 'RoDZ_SplitBet28And31',
  RODZ_SPLITBET29AND30 = 'RoDZ_SplitBet29And30',
  RODZ_SPLITBET29AND32 = 'RoDZ_SplitBet29And32',
  RODZ_SPLITBET30AND33 = 'RoDZ_SplitBet30And33',
  RODZ_SPLITBET31AND32 = 'RoDZ_SplitBet31And32',
  RODZ_SPLITBET31AND34 = 'RoDZ_SplitBet31And34',
  RODZ_SPLITBET32AND33 = 'RoDZ_SplitBet32And33',
  RODZ_SPLITBET32AND35 = 'RoDZ_SplitBet32And35',
  RODZ_SPLITBET33AND36 = 'RoDZ_SplitBet33And36',
  RODZ_SPLITBET34AND35 = 'RoDZ_SplitBet34And35',
  RODZ_SPLITBET35AND36 = 'RoDZ_SplitBet35And36',
  RODZ_BLACK = 'RoDZ_Black',
  RODZ_COLUMNBET14ETC = 'RoDZ_ColumnBet14Etc',
  RODZ_COLUMNBET25ETC = 'RoDZ_ColumnBet25Etc',
  RODZ_COLUMNBET36ETC = 'RoDZ_ColumnBet36Etc',
  RODZ_DOZENBET112 = 'RoDZ_DozenBet112',
  RODZ_DOZENBET1324 = 'RoDZ_DozenBet1324',
  RODZ_DOZENBET2536 = 'RoDZ_DozenBet2536',
  RODZ_11850RETURNON0 = 'RoDZ_11850ReturnOn0',
  RODZ_ODD50RETURNON0 = 'RoDZ_Odd50ReturnOn0',
  RODZ_RED50RETURNON0 = 'RoDZ_Red50ReturnOn0',
  RODZ_EVEN50RETURNON0 = 'RoDZ_Even50ReturnOn0',
  RODZ_193650RETURNON0 = 'RoDZ_193650ReturnOn0',
  RODZ_STREETBET01AND2 = 'RoDZ_StreetBet01And2',
  RODZ_STREETBET12AND3 = 'RoDZ_StreetBet12And3',
  RODZ_STREETBET45AND6 = 'RoDZ_StreetBet45And6',
  RODZ_STREETBET78AND9 = 'RoDZ_StreetBet78And9',
  RODZ_BLACK50RETURNON0 = 'RoDZ_Black50ReturnOn0',
  RODZ_STREETBET00AND2AND3 = 'RoDZ_StreetBet00And2And3',
  RODZ_STREETBET0AND00AND2 = 'RoDZ_StreetBet0And00And2',
  RODZ_STREETBET1011AND12 = 'RoDZ_StreetBet1011And12',
  RODZ_STREETBET1314AND15 = 'RoDZ_StreetBet1314And15',
  RODZ_STREETBET1617AND18 = 'RoDZ_StreetBet1617And18',
  RODZ_STREETBET1920AND21 = 'RoDZ_StreetBet1920And21',
  RODZ_STREETBET2223AND24 = 'RoDZ_StreetBet2223And24',
  RODZ_STREETBET2526AND27 = 'RoDZ_StreetBet2526And27',
  RODZ_STREETBET2829AND30 = 'RoDZ_StreetBet2829And30',
  RODZ_STREETBET3132AND33 = 'RoDZ_StreetBet3132And33',
  RODZ_STREETBET3435AND36 = 'RoDZ_StreetBet3435And36',
  RODZ_STREETBET0AND00AND1AND2AND3 = 'RoDZ_StreetBet0And00And1And2And3',
  RODZ_SQUAREBET124AND5 = 'RoDZ_SquareBet124And5',
  RODZ_SQUAREBET235AND6 = 'RoDZ_SquareBet235And6',
  RODZ_SQUAREBET457AND8 = 'RoDZ_SquareBet457And8',
  RODZ_SQUAREBET568AND9 = 'RoDZ_SquareBet568And9',
  RODZ_SQUAREBET7810AND11 = 'RoDZ_SquareBet7810And11',
  RODZ_SQUAREBET8911AND12 = 'RoDZ_SquareBet8911And12',
  RODZ_LINEBET12345AND6 = 'RoDZ_LineBet12345And6',
  RODZ_LINEBET45678AND9 = 'RoDZ_LineBet45678And9',
  RODZ_LINEBET7891011AND12 = 'RoDZ_LineBet7891011And12',
  RODZ_LINEBET1011121314AND15 = 'RoDZ_LineBet1011121314And15',
  RODZ_LINEBET1314151617AND18 = 'RoDZ_LineBet1314151617And18',
  RODZ_LINEBET1617181920AND21 = 'RoDZ_LineBet1617181920And21',
  RODZ_LINEBET1920212223AND24 = 'RoDZ_LineBet1920212223And24',
  RODZ_LINEBET2223242526AND27 = 'RoDZ_LineBet2223242526And27',
  RODZ_LINEBET2526272829AND30 = 'RoDZ_LineBet2526272829And30',
  RODZ_LINEBET2829303132AND33 = 'RoDZ_LineBet2829303132And33',
  RODZ_LINEBET3132333435AND36 = 'RoDZ_LineBet3132333435And36',
  RODZ_SQUAREBET101113AND14 = 'RoDZ_SquareBet101113And14',
  RODZ_SQUAREBET111214AND15 = 'RoDZ_SquareBet111214And15',
  RODZ_SQUAREBET131416AND17 = 'RoDZ_SquareBet131416And17',
  RODZ_SQUAREBET141517AND18 = 'RoDZ_SquareBet141517And18',
  RODZ_SQUAREBET161719AND20 = 'RoDZ_SquareBet161719And20',
  RODZ_SQUAREBET171820AND21 = 'RoDZ_SquareBet171820And21',
  RODZ_SQUAREBET192022AND23 = 'RoDZ_SquareBet192022And23',
  RODZ_SQUAREBET202123AND24 = 'RoDZ_SquareBet202123And24',
  RODZ_SQUAREBET222325AND26 = 'RoDZ_SquareBet222325And26',
  RODZ_SQUAREBET232426AND27 = 'RoDZ_SquareBet232426And27',
  RODZ_SQUAREBET252628AND29 = 'RoDZ_SquareBet252628And29',
  RODZ_SQUAREBET262729AND30 = 'RoDZ_SquareBet262729And30',
  RODZ_SQUAREBET282931AND32 = 'RoDZ_SquareBet282931And32',
  RODZ_SQUAREBET293032AND33 = 'RoDZ_SquareBet293032And33',
  RODZ_SQUAREBET313234AND35 = 'RoDZ_SquareBet313234And35',
  RODZ_SQUAREBET323335AND36 = 'RoDZ_SquareBet323335And36',
  TRP_ANTEBET = 'TRP_AnteBet',
  TRP_CALLBET = 'TRP_CallBet',
  TRP_PAIRORBETTERBET = 'TRP_PairOrBetterBet',
  TRP_THREEPLUSTHREEBONUSBET = 'TRP_ThreePlusThreeBonusBet',
  THB_ANTEBET = 'THB_AnteBet',
  THB_BONUSBET = 'THB_BonusBet',
  THB_FLOPBET = 'THB_FlopBet',
  THB_TURNBET = 'THB_TurnBet',
  THB_RIVERBET = 'THB_RiverBet',
  THB_JACKPOTBET = 'THB_JackpotBet',
  DHP_FIRSTHANDAABONUSBET = 'DHP_FirstHandAABonusBet',
  DHP_FIRSTHANDANTEBET = 'DHP_FirstHandAnteBet',
  DHP_FIRSTHANDCALLBET = 'DHP_FirstHandCallBet',
  DHP_SECONDHANDAABONUSBET = 'DHP_SecondHandAABonusBet',
  DHP_SECONDHANDANTEBET = 'DHP_SecondHandAnteBet',
  DHP_SECONDHANDCALLBET = 'DHP_SecondHandCallBet',
  SBC_FIVECARDSBET = 'SBC_FiveCardsBet',
  SBC_NOWINBET = 'SBC_NoWinBet',
  SBC_SEVENCARDSBET = 'SBC_SevenCardsBet',
  SBC_THREECARDSBET = 'SBC_ThreeCardsBet',
  DT_TIE = 'DT_Tie',
  DT_TIGER = 'DT_Tiger',
  DT_DRAGON = 'DT_Dragon',
  DT_EVEN = 'DT_Even',
  DT_ODD = 'DT_Odd',
  DT_SUITEDTIE = 'DT_SuitedTie',
  ROU_DB_ATLEASTONEBALLON0 = 'ROU_DB_AtLeastOneBallOn0',
  ROU_DB_ATLEASTONEBALLON1 = 'ROU_DB_AtLeastOneBallOn1',
  ROU_DB_ATLEASTONEBALLON2 = 'ROU_DB_AtLeastOneBallOn2',
  ROU_DB_ATLEASTONEBALLON3 = 'ROU_DB_AtLeastOneBallOn3',
  ROU_DB_ATLEASTONEBALLON4 = 'ROU_DB_AtLeastOneBallOn4',
  ROU_DB_ATLEASTONEBALLON5 = 'ROU_DB_AtLeastOneBallOn5',
  ROU_DB_ATLEASTONEBALLON6 = 'ROU_DB_AtLeastOneBallOn6',
  ROU_DB_ATLEASTONEBALLON7 = 'ROU_DB_AtLeastOneBallOn7',
  ROU_DB_ATLEASTONEBALLON8 = 'ROU_DB_AtLeastOneBallOn8',
  ROU_DB_ATLEASTONEBALLON9 = 'ROU_DB_AtLeastOneBallOn9',
  ROU_DB_ATLEASTONEBALLON10 = 'ROU_DB_AtLeastOneBallOn10',
  ROU_DB_ATLEASTONEBALLON11 = 'ROU_DB_AtLeastOneBallOn11',
  ROU_DB_ATLEASTONEBALLON12 = 'ROU_DB_AtLeastOneBallOn12',
  ROU_DB_ATLEASTONEBALLON13 = 'ROU_DB_AtLeastOneBallOn13',
  ROU_DB_ATLEASTONEBALLON14 = 'ROU_DB_AtLeastOneBallOn14',
  ROU_DB_ATLEASTONEBALLON15 = 'ROU_DB_AtLeastOneBallOn15',
  ROU_DB_ATLEASTONEBALLON16 = 'ROU_DB_AtLeastOneBallOn16',
  ROU_DB_ATLEASTONEBALLON17 = 'ROU_DB_AtLeastOneBallOn17',
  ROU_DB_ATLEASTONEBALLON18 = 'ROU_DB_AtLeastOneBallOn18',
  ROU_DB_ATLEASTONEBALLON19 = 'ROU_DB_AtLeastOneBallOn19',
  ROU_DB_ATLEASTONEBALLON20 = 'ROU_DB_AtLeastOneBallOn20',
  ROU_DB_ATLEASTONEBALLON21 = 'ROU_DB_AtLeastOneBallOn21',
  ROU_DB_ATLEASTONEBALLON22 = 'ROU_DB_AtLeastOneBallOn22',
  ROU_DB_ATLEASTONEBALLON23 = 'ROU_DB_AtLeastOneBallOn23',
  ROU_DB_ATLEASTONEBALLON24 = 'ROU_DB_AtLeastOneBallOn24',
  ROU_DB_ATLEASTONEBALLON25 = 'ROU_DB_AtLeastOneBallOn25',
  ROU_DB_ATLEASTONEBALLON26 = 'ROU_DB_AtLeastOneBallOn26',
  ROU_DB_ATLEASTONEBALLON27 = 'ROU_DB_AtLeastOneBallOn27',
  ROU_DB_ATLEASTONEBALLON28 = 'ROU_DB_AtLeastOneBallOn28',
  ROU_DB_ATLEASTONEBALLON29 = 'ROU_DB_AtLeastOneBallOn29',
  ROU_DB_ATLEASTONEBALLON30 = 'ROU_DB_AtLeastOneBallOn30',
  ROU_DB_ATLEASTONEBALLON31 = 'ROU_DB_AtLeastOneBallOn31',
  ROU_DB_ATLEASTONEBALLON32 = 'ROU_DB_AtLeastOneBallOn32',
  ROU_DB_ATLEASTONEBALLON33 = 'ROU_DB_AtLeastOneBallOn33',
  ROU_DB_ATLEASTONEBALLON34 = 'ROU_DB_AtLeastOneBallOn34',
  ROU_DB_ATLEASTONEBALLON35 = 'ROU_DB_AtLeastOneBallOn35',
  ROU_DB_ATLEASTONEBALLON36 = 'ROU_DB_AtLeastOneBallOn36',
  ROU_DB_ATLEASTONEBALLONSPLIT0AND1 = 'ROU_DB_AtLeastOneBallOnSplit0And1',
  ROU_DB_ATLEASTONEBALLONSPLIT0AND2 = 'ROU_DB_AtLeastOneBallOnSplit0And2',
  ROU_DB_ATLEASTONEBALLONSPLIT0AND3 = 'ROU_DB_AtLeastOneBallOnSplit0And3',
  ROU_DB_ATLEASTONEBALLONSPLIT1AND2 = 'ROU_DB_AtLeastOneBallOnSplit1And2',
  ROU_DB_ATLEASTONEBALLONSPLIT1AND4 = 'ROU_DB_AtLeastOneBallOnSplit1And4',
  ROU_DB_ATLEASTONEBALLONSPLIT2AND3 = 'ROU_DB_AtLeastOneBallOnSplit2And3',
  ROU_DB_ATLEASTONEBALLONSPLIT2AND5 = 'ROU_DB_AtLeastOneBallOnSplit2And5',
  ROU_DB_ATLEASTONEBALLONSPLIT3AND6 = 'ROU_DB_AtLeastOneBallOnSplit3And6',
  ROU_DB_ATLEASTONEBALLONSPLIT4AND5 = 'ROU_DB_AtLeastOneBallOnSplit4And5',
  ROU_DB_ATLEASTONEBALLONSPLIT4AND7 = 'ROU_DB_AtLeastOneBallOnSplit4And7',
  ROU_DB_ATLEASTONEBALLONSPLIT5AND6 = 'ROU_DB_AtLeastOneBallOnSplit5And6',
  ROU_DB_ATLEASTONEBALLONSPLIT5AND8 = 'ROU_DB_AtLeastOneBallOnSplit5And8',
  ROU_DB_ATLEASTONEBALLONSPLIT6AND9 = 'ROU_DB_AtLeastOneBallOnSplit6And9',
  ROU_DB_ATLEASTONEBALLONSPLIT7AND8 = 'ROU_DB_AtLeastOneBallOnSplit7And8',
  ROU_DB_ATLEASTONEBALLONSPLIT7AND10 = 'ROU_DB_AtLeastOneBallOnSplit7And10',
  ROU_DB_ATLEASTONEBALLONSPLIT8AND9 = 'ROU_DB_AtLeastOneBallOnSplit8And9',
  ROU_DB_ATLEASTONEBALLONSPLIT8AND11 = 'ROU_DB_AtLeastOneBallOnSplit8And11',
  ROU_DB_ATLEASTONEBALLONSPLIT9AND12 = 'ROU_DB_AtLeastOneBallOnSplit9And12',
  ROU_DB_ATLEASTONEBALLONSPLIT10AND11 = 'ROU_DB_AtLeastOneBallOnSplit10And11',
  ROU_DB_ATLEASTONEBALLONSPLIT10AND13 = 'ROU_DB_AtLeastOneBallOnSplit10And13',
  ROU_DB_ATLEASTONEBALLONSPLIT11AND12 = 'ROU_DB_AtLeastOneBallOnSplit11And12',
  ROU_DB_ATLEASTONEBALLONSPLIT11AND14 = 'ROU_DB_AtLeastOneBallOnSplit11And14',
  ROU_DB_ATLEASTONEBALLONSPLIT12AND15 = 'ROU_DB_AtLeastOneBallOnSplit12And15',
  ROU_DB_ATLEASTONEBALLONSPLIT13AND14 = 'ROU_DB_AtLeastOneBallOnSplit13And14',
  ROU_DB_ATLEASTONEBALLONSPLIT13AND16 = 'ROU_DB_AtLeastOneBallOnSplit13And16',
  ROU_DB_ATLEASTONEBALLONSPLIT14AND15 = 'ROU_DB_AtLeastOneBallOnSplit14And15',
  ROU_DB_ATLEASTONEBALLONSPLIT14AND17 = 'ROU_DB_AtLeastOneBallOnSplit14And17',
  ROU_DB_ATLEASTONEBALLONSPLIT15AND18 = 'ROU_DB_AtLeastOneBallOnSplit15And18',
  ROU_DB_ATLEASTONEBALLONSPLIT16AND17 = 'ROU_DB_AtLeastOneBallOnSplit16And17',
  ROU_DB_ATLEASTONEBALLONSPLIT16AND19 = 'ROU_DB_AtLeastOneBallOnSplit16And19',
  ROU_DB_ATLEASTONEBALLONSPLIT17AND18 = 'ROU_DB_AtLeastOneBallOnSplit17And18',
  ROU_DB_ATLEASTONEBALLONSPLIT17AND20 = 'ROU_DB_AtLeastOneBallOnSplit17And20',
  ROU_DB_ATLEASTONEBALLONSPLIT18AND21 = 'ROU_DB_AtLeastOneBallOnSplit18And21',
  ROU_DB_ATLEASTONEBALLONSPLIT19AND20 = 'ROU_DB_AtLeastOneBallOnSplit19And20',
  ROU_DB_ATLEASTONEBALLONSPLIT19AND22 = 'ROU_DB_AtLeastOneBallOnSplit19And22',
  ROU_DB_ATLEASTONEBALLONSPLIT20AND21 = 'ROU_DB_AtLeastOneBallOnSplit20And21',
  ROU_DB_ATLEASTONEBALLONSPLIT20AND23 = 'ROU_DB_AtLeastOneBallOnSplit20And23',
  ROU_DB_ATLEASTONEBALLONSPLIT21AND24 = 'ROU_DB_AtLeastOneBallOnSplit21And24',
  ROU_DB_ATLEASTONEBALLONSPLIT22AND23 = 'ROU_DB_AtLeastOneBallOnSplit22And23',
  ROU_DB_ATLEASTONEBALLONSPLIT22AND25 = 'ROU_DB_AtLeastOneBallOnSplit22And25',
  ROU_DB_ATLEASTONEBALLONSPLIT23AND24 = 'ROU_DB_AtLeastOneBallOnSplit23And24',
  ROU_DB_ATLEASTONEBALLONSPLIT23AND26 = 'ROU_DB_AtLeastOneBallOnSplit23And26',
  ROU_DB_ATLEASTONEBALLONSPLIT24AND27 = 'ROU_DB_AtLeastOneBallOnSplit24And27',
  ROU_DB_ATLEASTONEBALLONSPLIT25AND26 = 'ROU_DB_AtLeastOneBallOnSplit25And26',
  ROU_DB_ATLEASTONEBALLONSPLIT25AND28 = 'ROU_DB_AtLeastOneBallOnSplit25And28',
  ROU_DB_ATLEASTONEBALLONSPLIT26AND27 = 'ROU_DB_AtLeastOneBallOnSplit26And27',
  ROU_DB_ATLEASTONEBALLONSPLIT26AND29 = 'ROU_DB_AtLeastOneBallOnSplit26And29',
  ROU_DB_ATLEASTONEBALLONSPLIT27AND30 = 'ROU_DB_AtLeastOneBallOnSplit27And30',
  ROU_DB_ATLEASTONEBALLONSPLIT28AND29 = 'ROU_DB_AtLeastOneBallOnSplit28And29',
  ROU_DB_ATLEASTONEBALLONSPLIT28AND31 = 'ROU_DB_AtLeastOneBallOnSplit28And31',
  ROU_DB_ATLEASTONEBALLONSPLIT29AND30 = 'ROU_DB_AtLeastOneBallOnSplit29And30',
  ROU_DB_ATLEASTONEBALLONSPLIT29AND32 = 'ROU_DB_AtLeastOneBallOnSplit29And32',
  ROU_DB_ATLEASTONEBALLONSPLIT30AND33 = 'ROU_DB_AtLeastOneBallOnSplit30And33',
  ROU_DB_ATLEASTONEBALLONSPLIT31AND32 = 'ROU_DB_AtLeastOneBallOnSplit31And32',
  ROU_DB_ATLEASTONEBALLONSPLIT31AND34 = 'ROU_DB_AtLeastOneBallOnSplit31And34',
  ROU_DB_ATLEASTONEBALLONSPLIT32AND33 = 'ROU_DB_AtLeastOneBallOnSplit32And33',
  ROU_DB_ATLEASTONEBALLONSPLIT32AND35 = 'ROU_DB_AtLeastOneBallOnSplit32And35',
  ROU_DB_ATLEASTONEBALLONSPLIT33AND36 = 'ROU_DB_AtLeastOneBallOnSplit33And36',
  ROU_DB_ATLEASTONEBALLONSPLIT34AND35 = 'ROU_DB_AtLeastOneBallOnSplit34And35',
  ROU_DB_ATLEASTONEBALLONSPLIT35AND36 = 'ROU_DB_AtLeastOneBallOnSplit35And36',
  ROU_DB_ATLEASTONEBALLONSTREET01AND2 = 'ROU_DB_AtLeastOneBallOnStreet01And2',
  ROU_DB_ATLEASTONEBALLONSTREET02AND3 = 'ROU_DB_AtLeastOneBallOnStreet02And3',
  ROU_DB_ATLEASTONEBALLONSTREET12AND3 = 'ROU_DB_AtLeastOneBallOnStreet12And3',
  ROU_DB_ATLEASTONEBALLONSTREET45AND6 = 'ROU_DB_AtLeastOneBallOnStreet45And6',
  ROU_DB_ATLEASTONEBALLONSTREET78AND9 = 'ROU_DB_AtLeastOneBallOnStreet78And9',
  ROU_DB_BOTHBALLSON0 = 'ROU_DB_BothBallsOn0',
  ROU_DB_BOTHBALLSON1 = 'ROU_DB_BothBallsOn1',
  ROU_DB_BOTHBALLSON2 = 'ROU_DB_BothBallsOn2',
  ROU_DB_BOTHBALLSON3 = 'ROU_DB_BothBallsOn3',
  ROU_DB_BOTHBALLSON4 = 'ROU_DB_BothBallsOn4',
  ROU_DB_BOTHBALLSON5 = 'ROU_DB_BothBallsOn5',
  ROU_DB_BOTHBALLSON6 = 'ROU_DB_BothBallsOn6',
  ROU_DB_BOTHBALLSON7 = 'ROU_DB_BothBallsOn7',
  ROU_DB_BOTHBALLSON8 = 'ROU_DB_BothBallsOn8',
  ROU_DB_BOTHBALLSON9 = 'ROU_DB_BothBallsOn9',
  ROU_DB_BOTHBALLSON10 = 'ROU_DB_BothBallsOn10',
  ROU_DB_BOTHBALLSON11 = 'ROU_DB_BothBallsOn11',
  ROU_DB_BOTHBALLSON12 = 'ROU_DB_BothBallsOn12',
  ROU_DB_BOTHBALLSON13 = 'ROU_DB_BothBallsOn13',
  ROU_DB_BOTHBALLSON14 = 'ROU_DB_BothBallsOn14',
  ROU_DB_BOTHBALLSON15 = 'ROU_DB_BothBallsOn15',
  ROU_DB_BOTHBALLSON16 = 'ROU_DB_BothBallsOn16',
  ROU_DB_BOTHBALLSON17 = 'ROU_DB_BothBallsOn17',
  ROU_DB_BOTHBALLSON18 = 'ROU_DB_BothBallsOn18',
  ROU_DB_BOTHBALLSON19 = 'ROU_DB_BothBallsOn19',
  ROU_DB_BOTHBALLSON20 = 'ROU_DB_BothBallsOn20',
  ROU_DB_BOTHBALLSON21 = 'ROU_DB_BothBallsOn21',
  ROU_DB_BOTHBALLSON22 = 'ROU_DB_BothBallsOn22',
  ROU_DB_BOTHBALLSON23 = 'ROU_DB_BothBallsOn23',
  ROU_DB_BOTHBALLSON24 = 'ROU_DB_BothBallsOn24',
  ROU_DB_BOTHBALLSON25 = 'ROU_DB_BothBallsOn25',
  ROU_DB_BOTHBALLSON26 = 'ROU_DB_BothBallsOn26',
  ROU_DB_BOTHBALLSON27 = 'ROU_DB_BothBallsOn27',
  ROU_DB_BOTHBALLSON28 = 'ROU_DB_BothBallsOn28',
  ROU_DB_BOTHBALLSON29 = 'ROU_DB_BothBallsOn29',
  ROU_DB_BOTHBALLSON30 = 'ROU_DB_BothBallsOn30',
  ROU_DB_BOTHBALLSON31 = 'ROU_DB_BothBallsOn31',
  ROU_DB_BOTHBALLSON32 = 'ROU_DB_BothBallsOn32',
  ROU_DB_BOTHBALLSON33 = 'ROU_DB_BothBallsOn33',
  ROU_DB_BOTHBALLSON34 = 'ROU_DB_BothBallsOn34',
  ROU_DB_BOTHBALLSON35 = 'ROU_DB_BothBallsOn35',
  ROU_DB_BOTHBALLSON36 = 'ROU_DB_BothBallsOn36',
  ROU_DB_ATLEASTONEBALLONSTREET1011AND12 = 'ROU_DB_AtLeastOneBallOnStreet1011And12',
  ROU_DB_ATLEASTONEBALLONSTREET1314AND15 = 'ROU_DB_AtLeastOneBallOnStreet1314And15',
  ROU_DB_ATLEASTONEBALLONSTREET1617AND18 = 'ROU_DB_AtLeastOneBallOnStreet1617And18',
  ROU_DB_ATLEASTONEBALLONSTREET1920AND21 = 'ROU_DB_AtLeastOneBallOnStreet1920And21',
  ROU_DB_ATLEASTONEBALLONSTREET2223AND24 = 'ROU_DB_AtLeastOneBallOnStreet2223And24',
  ROU_DB_ATLEASTONEBALLONSTREET2526AND27 = 'ROU_DB_AtLeastOneBallOnStreet2526And27',
  ROU_DB_ATLEASTONEBALLONSTREET2829AND30 = 'ROU_DB_AtLeastOneBallOnStreet2829And30',
  ROU_DB_ATLEASTONEBALLONSTREET3132AND33 = 'ROU_DB_AtLeastOneBallOnStreet3132And33',
  ROU_DB_ATLEASTONEBALLONSTREET3435AND36 = 'ROU_DB_AtLeastOneBallOnStreet3435And36',
  ROU_DB_BOTHBALLSONANYSAMENUMBER = 'ROU_DB_BothBallsOnAnySameNumber',
  ROU_DB_ONEBALLONREDONEONBLACK = 'ROU_DB_OneBallOnRedOneOnBlack',
  ROU_DB_ATLEASTONEBALLONSQUARE012AND3 = 'ROU_DB_AtLeastOneBallOnSquare012And3',
  ROU_DB_ATLEASTONEBALLONSQUARE124AND5 = 'ROU_DB_AtLeastOneBallOnSquare124And5',
  ROU_DB_ATLEASTONEBALLONSQUARE235AND6 = 'ROU_DB_AtLeastOneBallOnSquare235And6',
  ROU_DB_ATLEASTONEBALLONSQUARE457AND8 = 'ROU_DB_AtLeastOneBallOnSquare457And8',
  ROU_DB_ATLEASTONEBALLONSQUARE568AND9 = 'ROU_DB_AtLeastOneBallOnSquare568And9',
  ROU_DB_ATLEASTONEBALLONSQUARE7810AND11 = 'ROU_DB_AtLeastOneBallOnSquare7810And11',
  ROU_DB_ATLEASTONEBALLONSQUARE8911AND12 = 'ROU_DB_AtLeastOneBallOnSquare8911And12',
  ROU_DB_BOTHBALLSONODD = 'ROU_DB_BothBallsOnOdd',
  ROU_DB_BOTHBALLSONRED = 'ROU_DB_BothBallsOnRed',
  ROU_DB_BOTHBALLSONEVEN = 'ROU_DB_BothBallsOnEven',
  ROU_DB_BOTHBALLSONBLACK = 'ROU_DB_BothBallsOnBlack',
  ROU_DB_BOTHBALLSONCOLUMN14ETC = 'ROU_DB_BothBallsOnColumn14Etc',
  ROU_DB_BOTHBALLSONCOLUMN25ETC = 'ROU_DB_BothBallsOnColumn25Etc',
  ROU_DB_BOTHBALLSONCOLUMN36ETC = 'ROU_DB_BothBallsOnColumn36Etc',
  ROU_DB_BOTHBALLSONDOZEN112 = 'ROU_DB_BothBallsOnDozen112',
  ROU_DB_BOTHBALLSONDOZEN1324 = 'ROU_DB_BothBallsOnDozen1324',
  ROU_DB_BOTHBALLSONDOZEN2536 = 'ROU_DB_BothBallsOnDozen2536',
  ROU_DB_ATLEASTONEBALLONLINE12345AND6 = 'ROU_DB_AtLeastOneBallOnLine12345And6',
  ROU_DB_ATLEASTONEBALLONLINE45678AND9 = 'ROU_DB_AtLeastOneBallOnLine45678And9',
  ROU_DB_ATLEASTONEBALLONLINE7891011AND12 = 'ROU_DB_AtLeastOneBallOnLine7891011And12',
  ROU_DB_ATLEASTONEBALLONLINE1011121314AND15 = 'ROU_DB_AtLeastOneBallOnLine1011121314And15',
  ROU_DB_ATLEASTONEBALLONLINE1314151617AND18 = 'ROU_DB_AtLeastOneBallOnLine1314151617And18',
  ROU_DB_ATLEASTONEBALLONLINE1617181920AND21 = 'ROU_DB_AtLeastOneBallOnLine1617181920And21',
  ROU_DB_ATLEASTONEBALLONLINE1920212223AND24 = 'ROU_DB_AtLeastOneBallOnLine1920212223And24',
  ROU_DB_ATLEASTONEBALLONLINE2223242526AND27 = 'ROU_DB_AtLeastOneBallOnLine2223242526And27',
  ROU_DB_ATLEASTONEBALLONLINE2526272829AND30 = 'ROU_DB_AtLeastOneBallOnLine2526272829And30',
  ROU_DB_ATLEASTONEBALLONLINE2829303132AND33 = 'ROU_DB_AtLeastOneBallOnLine2829303132And33',
  ROU_DB_ATLEASTONEBALLONLINE3132333435AND36 = 'ROU_DB_AtLeastOneBallOnLine3132333435And36',
  ROU_DB_ATLEASTONEBALLONSQUARE101113AND14 = 'ROU_DB_AtLeastOneBallOnSquare101113And14',
  ROU_DB_ATLEASTONEBALLONSQUARE111214AND15 = 'ROU_DB_AtLeastOneBallOnSquare111214And15',
  ROU_DB_ATLEASTONEBALLONSQUARE131416AND17 = 'ROU_DB_AtLeastOneBallOnSquare131416And17',
  ROU_DB_ATLEASTONEBALLONSQUARE141517AND18 = 'ROU_DB_AtLeastOneBallOnSquare141517And18',
  ROU_DB_ATLEASTONEBALLONSQUARE161719AND20 = 'ROU_DB_AtLeastOneBallOnSquare161719And20',
  ROU_DB_ATLEASTONEBALLONSQUARE171820AND21 = 'ROU_DB_AtLeastOneBallOnSquare171820And21',
  ROU_DB_ATLEASTONEBALLONSQUARE192022AND23 = 'ROU_DB_AtLeastOneBallOnSquare192022And23',
  ROU_DB_ATLEASTONEBALLONSQUARE202123AND24 = 'ROU_DB_AtLeastOneBallOnSquare202123And24',
  ROU_DB_ATLEASTONEBALLONSQUARE222325AND26 = 'ROU_DB_AtLeastOneBallOnSquare222325And26',
  ROU_DB_ATLEASTONEBALLONSQUARE232426AND27 = 'ROU_DB_AtLeastOneBallOnSquare232426And27',
  ROU_DB_ATLEASTONEBALLONSQUARE252628AND29 = 'ROU_DB_AtLeastOneBallOnSquare252628And29',
  ROU_DB_ATLEASTONEBALLONSQUARE262729AND30 = 'ROU_DB_AtLeastOneBallOnSquare262729And30',
  ROU_DB_ATLEASTONEBALLONSQUARE282931AND32 = 'ROU_DB_AtLeastOneBallOnSquare282931And32',
  ROU_DB_ATLEASTONEBALLONSQUARE293032AND33 = 'ROU_DB_AtLeastOneBallOnSquare293032And33',
  ROU_DB_ATLEASTONEBALLONSQUARE313234AND35 = 'ROU_DB_AtLeastOneBallOnSquare313234And35',
  ROU_DB_ATLEASTONEBALLONSQUARE323335AND36 = 'ROU_DB_AtLeastOneBallOnSquare323335And36',
  ROU_DB_BOTHBALLSON118 = 'ROU_DB_BothBallsOn118',
  ROU_DB_BOTHBALLSON1936 = 'ROU_DB_BothBallsOn1936',
  MON_1 = 'MON_1',
  MON_2 = 'MON_2',
  MON_5 = 'MON_5',
  MON_10 = 'MON_10',
  MON_2_ROLLS = 'MON_2_Rolls',
  MON_4_ROLLS = 'MON_4_Rolls',
  DOND_SPINBET = 'DOND_SpinBet',
  DOND_TOPUPBET = 'DOND_TopUpBet',
  DICE_TOTAL10 = 'DICE_Total10',
  DICE_TOTAL11 = 'DICE_Total11',
  DICE_TOTAL12 = 'DICE_Total12',
  DICE_TOTAL13 = 'DICE_Total13',
  DICE_TOTAL14 = 'DICE_Total14',
  DICE_TOTAL15 = 'DICE_Total15',
  DICE_TOTAL16 = 'DICE_Total16',
  DICE_TOTAL17 = 'DICE_Total17',
  DICE_TOTAL18 = 'DICE_Total18',
  DICE_TOTAL3 = 'DICE_Total3',
  DICE_TOTAL4 = 'DICE_Total4',
  DICE_TOTAL5 = 'DICE_Total5',
  DICE_TOTAL6 = 'DICE_Total6',
  DICE_TOTAL7 = 'DICE_Total7',
  DICE_TOTAL8 = 'DICE_Total8',
  DICE_TOTAL9 = 'DICE_Total9',
  SICBO_1 = 'SicBo_1',
  SICBO_2 = 'SicBo_2',
  SICBO_3 = 'SicBo_3',
  SICBO_4 = 'SicBo_4',
  SICBO_5 = 'SicBo_5',
  SICBO_6 = 'SicBo_6',
  SICBO_BIG = 'SicBo_Big',
  SICBO_COMBO1AND2 = 'SicBo_Combo1And2',
  SICBO_COMBO1AND3 = 'SicBo_Combo1And3',
  SICBO_COMBO1AND4 = 'SicBo_Combo1And4',
  SICBO_COMBO1AND5 = 'SicBo_Combo1And5',
  SICBO_COMBO1AND6 = 'SicBo_Combo1And6',
  SICBO_COMBO2AND3 = 'SicBo_Combo2And3',
  SICBO_COMBO2AND4 = 'SicBo_Combo2And4',
  SICBO_COMBO2AND5 = 'SicBo_Combo2And5',
  SICBO_COMBO2AND6 = 'SicBo_Combo2And6',
  SICBO_COMBO3AND4 = 'SicBo_Combo3And4',
  SICBO_COMBO3AND5 = 'SicBo_Combo3And5',
  SICBO_COMBO3AND6 = 'SicBo_Combo3And6',
  SICBO_COMBO4AND5 = 'SicBo_Combo4And5',
  SICBO_COMBO4AND6 = 'SicBo_Combo4And6',
  SICBO_COMBO5AND6 = 'SicBo_Combo5And6',
  SICBO_DOUBLE1 = 'SicBo_Double1',
  SICBO_DOUBLE2 = 'SicBo_Double2',
  SICBO_DOUBLE3 = 'SicBo_Double3',
  SICBO_DOUBLE4 = 'SicBo_Double4',
  SICBO_DOUBLE5 = 'SicBo_Double5',
  SICBO_DOUBLE6 = 'SicBo_Double6',
  SICBO_EVEN = 'SicBo_Even',
  SICBO_ODD = 'SicBo_Odd',
  SICBO_SMALL = 'SicBo_Small',
  SICBO_TOTAL10 = 'SicBo_Total10',
  SICBO_TOTAL11 = 'SicBo_Total11',
  SICBO_TOTAL12 = 'SicBo_Total12',
  SICBO_TOTAL13 = 'SicBo_Total13',
  SICBO_TOTAL14 = 'SicBo_Total14',
  SICBO_TOTAL15 = 'SicBo_Total15',
  SICBO_TOTAL16 = 'SicBo_Total16',
  SICBO_TOTAL17 = 'SicBo_Total17',
  SICBO_TOTAL4 = 'SicBo_Total4',
  SICBO_TOTAL5 = 'SicBo_Total5',
  SICBO_TOTAL6 = 'SicBo_Total6',
  SICBO_TOTAL7 = 'SicBo_Total7',
  SICBO_TOTAL8 = 'SicBo_Total8',
  SICBO_TOTAL9 = 'SicBo_Total9',
  SICBO_TRIPLE = 'SicBo_Triple',
  SICBO_TRIPLE1 = 'SicBo_Triple1',
  SICBO_TRIPLE2 = 'SicBo_Triple2',
  SICBO_TRIPLE3 = 'SicBo_Triple3',
  SICBO_TRIPLE4 = 'SicBo_Triple4',
  SICBO_TRIPLE5 = 'SicBo_Triple5',
  SICBO_TRIPLE6 = 'SicBo_Triple6',
  BAC_PLAYER_LIGHTNING = 'BAC_Player_Lightning',
  BAC_BANKER_LIGHTNING = 'BAC_Banker_Lightning',
  BAC_TIE_LIGHTNING = 'BAC_Tie_Lightning',
  BAC_PLAYERPAIR_LIGHTNING = 'BAC_PlayerPair_Lightning',
  BAC_BANKERPAIR_LIGHTNING = 'BAC_BankerPair_Lightning',
  BAC_LIGHTNING = 'BAC_Lightning',
  MB_CARD001 = 'MB_Card001',
  MB_CARD002 = 'MB_Card002',
  MB_CARD003 = 'MB_Card003',
  MB_CARD004 = 'MB_Card004',
  MB_CARD005 = 'MB_Card005',
  MB_CARD006 = 'MB_Card006',
  MB_CARD007 = 'MB_Card007',
  MB_CARD008 = 'MB_Card008',
  MB_CARD009 = 'MB_Card009',
  MB_CARD010 = 'MB_Card010',
  MB_CARD011 = 'MB_Card011',
  MB_CARD012 = 'MB_Card012',
  MB_CARD013 = 'MB_Card013',
  MB_CARD014 = 'MB_Card014',
  MB_CARD015 = 'MB_Card015',
  MB_CARD016 = 'MB_Card016',
  MB_CARD017 = 'MB_Card017',
  MB_CARD018 = 'MB_Card018',
  MB_CARD019 = 'MB_Card019',
  MB_CARD020 = 'MB_Card020',
  MB_CARD021 = 'MB_Card021',
  MB_CARD022 = 'MB_Card022',
  MB_CARD023 = 'MB_Card023',
  MB_CARD024 = 'MB_Card024',
  MB_CARD025 = 'MB_Card025',
  MB_CARD026 = 'MB_Card026',
  MB_CARD027 = 'MB_Card027',
  MB_CARD028 = 'MB_Card028',
  MB_CARD029 = 'MB_Card029',
  MB_CARD030 = 'MB_Card030',
  MB_CARD031 = 'MB_Card031',
  MB_CARD032 = 'MB_Card032',
  MB_CARD033 = 'MB_Card033',
  MB_CARD034 = 'MB_Card034',
  MB_CARD035 = 'MB_Card035',
  MB_CARD036 = 'MB_Card036',
  MB_CARD037 = 'MB_Card037',
  MB_CARD038 = 'MB_Card038',
  MB_CARD039 = 'MB_Card039',
  MB_CARD040 = 'MB_Card040',
  MB_CARD041 = 'MB_Card041',
  MB_CARD042 = 'MB_Card042',
  MB_CARD043 = 'MB_Card043',
  MB_CARD044 = 'MB_Card044',
  MB_CARD045 = 'MB_Card045',
  MB_CARD046 = 'MB_Card046',
  MB_CARD047 = 'MB_Card047',
  MB_CARD048 = 'MB_Card048',
  MB_CARD049 = 'MB_Card049',
  MB_CARD050 = 'MB_Card050',
  MB_CARD051 = 'MB_Card051',
  MB_CARD052 = 'MB_Card052',
  MB_CARD053 = 'MB_Card053',
  MB_CARD054 = 'MB_Card054',
  MB_CARD055 = 'MB_Card055',
  MB_CARD056 = 'MB_Card056',
  MB_CARD057 = 'MB_Card057',
  MB_CARD058 = 'MB_Card058',
  MB_CARD059 = 'MB_Card059',
  MB_CARD060 = 'MB_Card060',
  MB_CARD061 = 'MB_Card061',
  MB_CARD062 = 'MB_Card062',
  MB_CARD063 = 'MB_Card063',
  MB_CARD064 = 'MB_Card064',
  MB_CARD065 = 'MB_Card065',
  MB_CARD066 = 'MB_Card066',
  MB_CARD067 = 'MB_Card067',
  MB_CARD068 = 'MB_Card068',
  MB_CARD069 = 'MB_Card069',
  MB_CARD070 = 'MB_Card070',
  MB_CARD071 = 'MB_Card071',
  MB_CARD072 = 'MB_Card072',
  MB_CARD073 = 'MB_Card073',
  MB_CARD074 = 'MB_Card074',
  MB_CARD075 = 'MB_Card075',
  MB_CARD076 = 'MB_Card076',
  MB_CARD077 = 'MB_Card077',
  MB_CARD078 = 'MB_Card078',
  MB_CARD079 = 'MB_Card079',
  MB_CARD080 = 'MB_Card080',
  MB_CARD081 = 'MB_Card081',
  MB_CARD082 = 'MB_Card082',
  MB_CARD083 = 'MB_Card083',
  MB_CARD084 = 'MB_Card084',
  MB_CARD085 = 'MB_Card085',
  MB_CARD086 = 'MB_Card086',
  MB_CARD087 = 'MB_Card087',
  MB_CARD088 = 'MB_Card088',
  MB_CARD089 = 'MB_Card089',
  MB_CARD090 = 'MB_Card090',
  MB_CARD091 = 'MB_Card091',
  MB_CARD092 = 'MB_Card092',
  MB_CARD093 = 'MB_Card093',
  MB_CARD094 = 'MB_Card094',
  MB_CARD095 = 'MB_Card095',
  MB_CARD096 = 'MB_Card096',
  MB_CARD097 = 'MB_Card097',
  MB_CARD098 = 'MB_Card098',
  MB_CARD099 = 'MB_Card099',
  MB_CARD100 = 'MB_Card100',
  MB_CARD101 = 'MB_Card101',
  MB_CARD102 = 'MB_Card102',
  MB_CARD103 = 'MB_Card103',
  MB_CARD104 = 'MB_Card104',
  MB_CARD105 = 'MB_Card105',
  MB_CARD106 = 'MB_Card106',
  MB_CARD107 = 'MB_Card107',
  MB_CARD108 = 'MB_Card108',
  MB_CARD109 = 'MB_Card109',
  MB_CARD110 = 'MB_Card110',
  MB_CARD111 = 'MB_Card111',
  MB_CARD112 = 'MB_Card112',
  MB_CARD113 = 'MB_Card113',
  MB_CARD114 = 'MB_Card114',
  MB_CARD115 = 'MB_Card115',
  MB_CARD116 = 'MB_Card116',
  MB_CARD117 = 'MB_Card117',
  MB_CARD118 = 'MB_Card118',
  MB_CARD119 = 'MB_Card119',
  MB_CARD120 = 'MB_Card120',
  MB_CARD121 = 'MB_Card121',
  MB_CARD122 = 'MB_Card122',
  MB_CARD123 = 'MB_Card123',
  MB_CARD124 = 'MB_Card124',
  MB_CARD125 = 'MB_Card125',
  MB_CARD126 = 'MB_Card126',
  MB_CARD127 = 'MB_Card127',
  MB_CARD128 = 'MB_Card128',
  MB_CARD129 = 'MB_Card129',
  MB_CARD130 = 'MB_Card130',
  MB_CARD131 = 'MB_Card131',
  MB_CARD132 = 'MB_Card132',
  MB_CARD133 = 'MB_Card133',
  MB_CARD134 = 'MB_Card134',
  MB_CARD135 = 'MB_Card135',
  MB_CARD136 = 'MB_Card136',
  MB_CARD137 = 'MB_Card137',
  MB_CARD138 = 'MB_Card138',
  MB_CARD139 = 'MB_Card139',
  MB_CARD140 = 'MB_Card140',
  MB_CARD141 = 'MB_Card141',
  MB_CARD142 = 'MB_Card142',
  MB_CARD143 = 'MB_Card143',
  MB_CARD144 = 'MB_Card144',
  MB_CARD145 = 'MB_Card145',
  MB_CARD146 = 'MB_Card146',
  MB_CARD147 = 'MB_Card147',
  MB_CARD148 = 'MB_Card148',
  MB_CARD149 = 'MB_Card149',
  MB_CARD150 = 'MB_Card150',
  MB_CARD151 = 'MB_Card151',
  MB_CARD152 = 'MB_Card152',
  MB_CARD153 = 'MB_Card153',
  MB_CARD154 = 'MB_Card154',
  MB_CARD155 = 'MB_Card155',
  MB_CARD156 = 'MB_Card156',
  MB_CARD157 = 'MB_Card157',
  MB_CARD158 = 'MB_Card158',
  MB_CARD159 = 'MB_Card159',
  MB_CARD160 = 'MB_Card160',
  MB_CARD161 = 'MB_Card161',
  MB_CARD162 = 'MB_Card162',
  MB_CARD163 = 'MB_Card163',
  MB_CARD164 = 'MB_Card164',
  MB_CARD165 = 'MB_Card165',
  MB_CARD166 = 'MB_Card166',
  MB_CARD167 = 'MB_Card167',
  MB_CARD168 = 'MB_Card168',
  MB_CARD169 = 'MB_Card169',
  MB_CARD170 = 'MB_Card170',
  MB_CARD171 = 'MB_Card171',
  MB_CARD172 = 'MB_Card172',
  MB_CARD173 = 'MB_Card173',
  MB_CARD174 = 'MB_Card174',
  MB_CARD175 = 'MB_Card175',
  MB_CARD176 = 'MB_Card176',
  MB_CARD177 = 'MB_Card177',
  MB_CARD178 = 'MB_Card178',
  MB_CARD179 = 'MB_Card179',
  MB_CARD180 = 'MB_Card180',
  MB_CARD181 = 'MB_Card181',
  MB_CARD182 = 'MB_Card182',
  MB_CARD183 = 'MB_Card183',
  MB_CARD184 = 'MB_Card184',
  MB_CARD185 = 'MB_Card185',
  MB_CARD186 = 'MB_Card186',
  MB_CARD187 = 'MB_Card187',
  MB_CARD188 = 'MB_Card188',
  MB_CARD189 = 'MB_Card189',
  MB_CARD190 = 'MB_Card190',
  MB_CARD191 = 'MB_Card191',
  MB_CARD192 = 'MB_Card192',
  MB_CARD193 = 'MB_Card193',
  MB_CARD194 = 'MB_Card194',
  MB_CARD195 = 'MB_Card195',
  MB_CARD196 = 'MB_Card196',
  MB_CARD197 = 'MB_Card197',
  MB_CARD198 = 'MB_Card198',
  MB_CARD199 = 'MB_Card199',
  MB_CARD200 = 'MB_Card200',
  MB_1CARDS = 'MB_1Cards',
  MB_2CARDS = 'MB_2Cards',
  MB_3CARDS = 'MB_3Cards',
  MB_4CARDS = 'MB_4Cards',
  MB_5CARDS = 'MB_5Cards',
  MB_6CARDS = 'MB_6Cards',
  MB_7CARDS = 'MB_7Cards',
  MB_8CARDS = 'MB_8Cards',
  MB_9CARDS = 'MB_9Cards',
  MB_10CARDS = 'MB_10Cards',
  MB_11CARDS = 'MB_11Cards',
  MB_12CARDS = 'MB_12Cards',
  MB_13CARDS = 'MB_13Cards',
  MB_14CARDS = 'MB_14Cards',
  MB_15CARDS = 'MB_15Cards',
  MB_16CARDS = 'MB_16Cards',
  MB_17CARDS = 'MB_17Cards',
  MB_18CARDS = 'MB_18Cards',
  MB_19CARDS = 'MB_19Cards',
  MB_20CARDS = 'MB_20Cards',
  MB_21CARDS = 'MB_21Cards',
  MB_22CARDS = 'MB_22Cards',
  MB_23CARDS = 'MB_23Cards',
  MB_24CARDS = 'MB_24Cards',
  MB_25CARDS = 'MB_25Cards',
  MB_26CARDS = 'MB_26Cards',
  MB_27CARDS = 'MB_27Cards',
  MB_28CARDS = 'MB_28Cards',
  MB_29CARDS = 'MB_29Cards',
  MB_30CARDS = 'MB_30Cards',
  MB_31CARDS = 'MB_31Cards',
  MB_32CARDS = 'MB_32Cards',
  MB_33CARDS = 'MB_33Cards',
  MB_34CARDS = 'MB_34Cards',
  MB_35CARDS = 'MB_35Cards',
  MB_36CARDS = 'MB_36Cards',
  MB_37CARDS = 'MB_37Cards',
  MB_38CARDS = 'MB_38Cards',
  MB_39CARDS = 'MB_39Cards',
  MB_40CARDS = 'MB_40Cards',
  MB_41CARDS = 'MB_41Cards',
  MB_42CARDS = 'MB_42Cards',
  MB_43CARDS = 'MB_43Cards',
  MB_44CARDS = 'MB_44Cards',
  MB_45CARDS = 'MB_45Cards',
  MB_46CARDS = 'MB_46Cards',
  MB_47CARDS = 'MB_47Cards',
  MB_48CARDS = 'MB_48Cards',
  MB_49CARDS = 'MB_49Cards',
  MB_50CARDS = 'MB_50Cards',
  MB_51CARDS = 'MB_51Cards',
  MB_52CARDS = 'MB_52Cards',
  MB_53CARDS = 'MB_53Cards',
  MB_54CARDS = 'MB_54Cards',
  MB_55CARDS = 'MB_55Cards',
  MB_56CARDS = 'MB_56Cards',
  MB_57CARDS = 'MB_57Cards',
  MB_58CARDS = 'MB_58Cards',
  MB_59CARDS = 'MB_59Cards',
  MB_60CARDS = 'MB_60Cards',
  MB_61CARDS = 'MB_61Cards',
  MB_62CARDS = 'MB_62Cards',
  MB_63CARDS = 'MB_63Cards',
  MB_64CARDS = 'MB_64Cards',
  MB_65CARDS = 'MB_65Cards',
  MB_66CARDS = 'MB_66Cards',
  MB_67CARDS = 'MB_67Cards',
  MB_68CARDS = 'MB_68Cards',
  MB_69CARDS = 'MB_69Cards',
  MB_70CARDS = 'MB_70Cards',
  MB_71CARDS = 'MB_71Cards',
  MB_72CARDS = 'MB_72Cards',
  MB_73CARDS = 'MB_73Cards',
  MB_74CARDS = 'MB_74Cards',
  MB_75CARDS = 'MB_75Cards',
  MB_76CARDS = 'MB_76Cards',
  MB_77CARDS = 'MB_77Cards',
  MB_78CARDS = 'MB_78Cards',
  MB_79CARDS = 'MB_79Cards',
  MB_80CARDS = 'MB_80Cards',
  MB_81CARDS = 'MB_81Cards',
  MB_82CARDS = 'MB_82Cards',
  MB_83CARDS = 'MB_83Cards',
  MB_84CARDS = 'MB_84Cards',
  MB_85CARDS = 'MB_85Cards',
  MB_86CARDS = 'MB_86Cards',
  MB_87CARDS = 'MB_87Cards',
  MB_88CARDS = 'MB_88Cards',
  MB_89CARDS = 'MB_89Cards',
  MB_90CARDS = 'MB_90Cards',
  MB_91CARDS = 'MB_91Cards',
  MB_92CARDS = 'MB_92Cards',
  MB_93CARDS = 'MB_93Cards',
  MB_94CARDS = 'MB_94Cards',
  MB_95CARDS = 'MB_95Cards',
  MB_96CARDS = 'MB_96Cards',
  MB_97CARDS = 'MB_97Cards',
  MB_98CARDS = 'MB_98Cards',
  MB_99CARDS = 'MB_99Cards',
  MB_100CARDS = 'MB_100Cards',
  MB_101CARDS = 'MB_101Cards',
  MB_102CARDS = 'MB_102Cards',
  MB_103CARDS = 'MB_103Cards',
  MB_104CARDS = 'MB_104Cards',
  MB_105CARDS = 'MB_105Cards',
  MB_106CARDS = 'MB_106Cards',
  MB_107CARDS = 'MB_107Cards',
  MB_108CARDS = 'MB_108Cards',
  MB_109CARDS = 'MB_109Cards',
  MB_110CARDS = 'MB_110Cards',
  MB_111CARDS = 'MB_111Cards',
  MB_112CARDS = 'MB_112Cards',
  MB_113CARDS = 'MB_113Cards',
  MB_114CARDS = 'MB_114Cards',
  MB_115CARDS = 'MB_115Cards',
  MB_116CARDS = 'MB_116Cards',
  MB_117CARDS = 'MB_117Cards',
  MB_118CARDS = 'MB_118Cards',
  MB_119CARDS = 'MB_119Cards',
  MB_120CARDS = 'MB_120Cards',
  MB_121CARDS = 'MB_121Cards',
  MB_122CARDS = 'MB_122Cards',
  MB_123CARDS = 'MB_123Cards',
  MB_124CARDS = 'MB_124Cards',
  MB_125CARDS = 'MB_125Cards',
  MB_126CARDS = 'MB_126Cards',
  MB_127CARDS = 'MB_127Cards',
  MB_128CARDS = 'MB_128Cards',
  MB_129CARDS = 'MB_129Cards',
  MB_130CARDS = 'MB_130Cards',
  MB_131CARDS = 'MB_131Cards',
  MB_132CARDS = 'MB_132Cards',
  MB_133CARDS = 'MB_133Cards',
  MB_134CARDS = 'MB_134Cards',
  MB_135CARDS = 'MB_135Cards',
  MB_136CARDS = 'MB_136Cards',
  MB_137CARDS = 'MB_137Cards',
  MB_138CARDS = 'MB_138Cards',
  MB_139CARDS = 'MB_139Cards',
  MB_140CARDS = 'MB_140Cards',
  MB_141CARDS = 'MB_141Cards',
  MB_142CARDS = 'MB_142Cards',
  MB_143CARDS = 'MB_143Cards',
  MB_144CARDS = 'MB_144Cards',
  MB_145CARDS = 'MB_145Cards',
  MB_146CARDS = 'MB_146Cards',
  MB_147CARDS = 'MB_147Cards',
  MB_148CARDS = 'MB_148Cards',
  MB_149CARDS = 'MB_149Cards',
  MB_150CARDS = 'MB_150Cards',
  MB_151CARDS = 'MB_151Cards',
  MB_152CARDS = 'MB_152Cards',
  MB_153CARDS = 'MB_153Cards',
  MB_154CARDS = 'MB_154Cards',
  MB_155CARDS = 'MB_155Cards',
  MB_156CARDS = 'MB_156Cards',
  MB_157CARDS = 'MB_157Cards',
  MB_158CARDS = 'MB_158Cards',
  MB_159CARDS = 'MB_159Cards',
  MB_160CARDS = 'MB_160Cards',
  MB_161CARDS = 'MB_161Cards',
  MB_162CARDS = 'MB_162Cards',
  MB_163CARDS = 'MB_163Cards',
  MB_164CARDS = 'MB_164Cards',
  MB_165CARDS = 'MB_165Cards',
  MB_166CARDS = 'MB_166Cards',
  MB_167CARDS = 'MB_167Cards',
  MB_168CARDS = 'MB_168Cards',
  MB_169CARDS = 'MB_169Cards',
  MB_170CARDS = 'MB_170Cards',
  MB_171CARDS = 'MB_171Cards',
  MB_172CARDS = 'MB_172Cards',
  MB_173CARDS = 'MB_173Cards',
  MB_174CARDS = 'MB_174Cards',
  MB_175CARDS = 'MB_175Cards',
  MB_176CARDS = 'MB_176Cards',
  MB_177CARDS = 'MB_177Cards',
  MB_178CARDS = 'MB_178Cards',
  MB_179CARDS = 'MB_179Cards',
  MB_180CARDS = 'MB_180Cards',
  MB_181CARDS = 'MB_181Cards',
  MB_182CARDS = 'MB_182Cards',
  MB_183CARDS = 'MB_183Cards',
  MB_184CARDS = 'MB_184Cards',
  MB_185CARDS = 'MB_185Cards',
  MB_186CARDS = 'MB_186Cards',
  MB_187CARDS = 'MB_187Cards',
  MB_188CARDS = 'MB_188Cards',
  MB_189CARDS = 'MB_189Cards',
  MB_190CARDS = 'MB_190Cards',
  MB_191CARDS = 'MB_191Cards',
  MB_192CARDS = 'MB_192Cards',
  MB_193CARDS = 'MB_193Cards',
  MB_194CARDS = 'MB_194Cards',
  MB_195CARDS = 'MB_195Cards',
  MB_196CARDS = 'MB_196Cards',
  MB_197CARDS = 'MB_197Cards',
  MB_198CARDS = 'MB_198Cards',
  MB_199CARDS = 'MB_199Cards',
  MB_200CARDS = 'MB_200Cards',
  PBJ_MAIN = 'PBJ_Main',
  PBJ_DOUBLEDOWN = 'PBJ_DoubleDown',
  PBJ_TRIPLEDOWN = 'PBJ_TripleDown',
  PBJ_QUADRUPLEDOWN = 'PBJ_QuadrupleDown',
  PBJ_SPLIT = 'PBJ_Split',
  PBJ_INSURANCE = 'PBJ_Insurance',
  PBJ_21_PLUS_3 = 'PBJ_21_plus_3',
  PBJ_ANYPAIR = 'PBJ_AnyPair',
  PBJ_HOT3 = 'PBJ_Hot3',
  PBJ_BUSTIT = 'PBJ_BustIt',
  PBJ_DOUBLEONFIRST = 'PBJ_DoubleOnFirst',
  PBJ_DOUBLEONSECOND = 'PBJ_DoubleOnSecond',
  PBJ_TRIPLEONFIRST = 'PBJ_TripleOnFirst',
  PBJ_TRIPLEONSECOND = 'PBJ_TripleOnSecond',
  PBJ_QUADRUPLEONFIRST = 'PBJ_QuadrupleOnFirst',
  PBJ_QUADRUPLEONSECOND = 'PBJ_QuadrupleOnSecond',
  CT_1 = 'CT_1',
  CT_2 = 'CT_2',
  CT_5 = 'CT_5',
  CT_10 = 'CT_10',
  CT_CASHHUNT = 'CT_CashHunt',
  CT_COINFLIP = 'CT_CoinFlip',
  CT_CRAZYTIME = 'CT_CrazyTime',
  CT_PACHINKO = 'CT_Pachinko',
  CRAPS_FIELD = 'Craps_Field',
  CRAPS_SEVEN = 'Craps_Seven',
  CRAPS_CRAPS = 'Craps_Craps',
  CRAPS_ELEVEN = 'Craps_Eleven',
  CRAPS_CRAP2 = 'Craps_Crap2',
  CRAPS_CRAP3 = 'Craps_Crap3',
  CRAPS_CRAP12 = 'Craps_Crap12',
  CRAPS_CE = 'Craps_CE',
  CRAPS_PASSLINE = 'Craps_PassLine',
  CRAPS_TAKEODDS = 'Craps_TakeOdds',
  CRAPS_DONTPASS = 'Craps_DontPass',
  CRAPS_LAYODDS = 'Craps_LayOdds',
  CRAPS_COME = 'Craps_Come',
  CRAPS_TAKEODDS4 = 'Craps_TakeOdds4',
  CRAPS_TAKEODDS5 = 'Craps_TakeOdds5',
  CRAPS_TAKEODDS6 = 'Craps_TakeOdds6',
  CRAPS_TAKEODDS8 = 'Craps_TakeOdds8',
  CRAPS_TAKEODDS9 = 'Craps_TakeOdds9',
  CRAPS_TAKEODDS10 = 'Craps_TakeOdds10',
  CRAPS_DONTCOME = 'Craps_DontCome',
  CRAPS_LAYODDS4 = 'Craps_LayOdds4',
  CRAPS_LAYODDS5 = 'Craps_LayOdds5',
  CRAPS_LAYODDS6 = 'Craps_LayOdds6',
  CRAPS_LAYODDS8 = 'Craps_LayOdds8',
  CRAPS_LAYODDS9 = 'Craps_LayOdds9',
  CRAPS_LAYODDS10 = 'Craps_LayOdds10',
  CRAPS_WIN4 = 'Craps_Win4',
  CRAPS_WIN5 = 'Craps_Win5',
  CRAPS_WIN6 = 'Craps_Win6',
  CRAPS_WIN8 = 'Craps_Win8',
  CRAPS_WIN9 = 'Craps_Win9',
  CRAPS_WIN10 = 'Craps_Win10',
  CRAPS_LOSE4 = 'Craps_Lose4',
  CRAPS_LOSE5 = 'Craps_Lose5',
  CRAPS_LOSE6 = 'Craps_Lose6',
  CRAPS_LOSE8 = 'Craps_Lose8',
  CRAPS_LOSE9 = 'Craps_Lose9',
  CRAPS_LOSE10 = 'Craps_Lose10',
  CRAPS_HARD4 = 'Craps_Hard4',
  CRAPS_HARD6 = 'Craps_Hard6',
  CRAPS_HARD8 = 'Craps_Hard8',
  CRAPS_HARD10 = 'Craps_Hard10',
  DOND_SPINZERORINGS = 'DOND_SpinZeroRings',
  DOND_SPINONERINGS = 'DOND_SpinOneRings',
  DOND_SPINTWORINGS = 'DOND_SpinTwoRings',
  DOND_SPINTHREERINGS = 'DOND_SpinThreeRings',
  DICE_LOW = 'DICE_Low',
  DICE_HIGH = 'DICE_High',
  DICE_TRIPLE = 'DICE_Triple',
  DICE_DOUBLE = 'DICE_Double',
  FT_1 = 'FT_1',
  FT_2 = 'FT_2',
  FT_3 = 'FT_3',
  FT_4 = 'FT_4',
  FT_ODD = 'FT_Odd',
  FT_EVEN = 'FT_Even',
  FT_BIG = 'FT_Big',
  FT_SMALL = 'FT_Small',
  FT_KWOK_1_2 = 'FT_Kwok_1_2',
  FT_KWOK_2_3 = 'FT_Kwok_2_3',
  FT_KWOK_3_4 = 'FT_Kwok_3_4',
  FT_KWOK_4_1 = 'FT_Kwok_4_1',
  FT_1_NIM_2 = 'FT_1_Nim_2',
  FT_1_NIM_3 = 'FT_1_Nim_3',
  FT_1_NIM_4 = 'FT_1_Nim_4',
  FT_2_NIM_1 = 'FT_2_Nim_1',
  FT_2_NIM_3 = 'FT_2_Nim_3',
  FT_2_NIM_4 = 'FT_2_Nim_4',
  FT_3_NIM_1 = 'FT_3_Nim_1',
  FT_3_NIM_2 = 'FT_3_Nim_2',
  FT_3_NIM_4 = 'FT_3_Nim_4',
  FT_4_NIM_1 = 'FT_4_Nim_1',
  FT_4_NIM_2 = 'FT_4_Nim_2',
  FT_4_NIM_3 = 'FT_4_Nim_3',
  FT_SSH_4_3_2 = 'FT_Ssh_4_3_2',
  FT_SSH_1_4_3 = 'FT_Ssh_1_4_3',
  FT_SSH_3_2_1 = 'FT_Ssh_3_2_1',
  FT_SSH_2_1_4 = 'FT_Ssh_2_1_4',
  MB_201CARDS = 'MB_201Cards',
  MB_202CARDS = 'MB_202Cards',
  MB_203CARDS = 'MB_203Cards',
  MB_204CARDS = 'MB_204Cards',
  MB_205CARDS = 'MB_205Cards',
  MB_206CARDS = 'MB_206Cards',
  MB_207CARDS = 'MB_207Cards',
  MB_208CARDS = 'MB_208Cards',
  MB_209CARDS = 'MB_209Cards',
  MB_210CARDS = 'MB_210Cards',
  MB_211CARDS = 'MB_211Cards',
  MB_212CARDS = 'MB_212Cards',
  MB_213CARDS = 'MB_213Cards',
  MB_214CARDS = 'MB_214Cards',
  MB_215CARDS = 'MB_215Cards',
  MB_216CARDS = 'MB_216Cards',
  MB_217CARDS = 'MB_217Cards',
  MB_218CARDS = 'MB_218Cards',
  MB_219CARDS = 'MB_219Cards',
  MB_220CARDS = 'MB_220Cards',
  MB_221CARDS = 'MB_221Cards',
  MB_222CARDS = 'MB_222Cards',
  MB_223CARDS = 'MB_223Cards',
  MB_224CARDS = 'MB_224Cards',
  MB_225CARDS = 'MB_225Cards',
  MB_226CARDS = 'MB_226Cards',
  MB_227CARDS = 'MB_227Cards',
  MB_228CARDS = 'MB_228Cards',
  MB_229CARDS = 'MB_229Cards',
  MB_230CARDS = 'MB_230Cards',
  MB_231CARDS = 'MB_231Cards',
  MB_232CARDS = 'MB_232Cards',
  MB_233CARDS = 'MB_233Cards',
  MB_234CARDS = 'MB_234Cards',
  MB_235CARDS = 'MB_235Cards',
  MB_236CARDS = 'MB_236Cards',
  MB_237CARDS = 'MB_237Cards',
  MB_238CARDS = 'MB_238Cards',
  MB_239CARDS = 'MB_239Cards',
  MB_240CARDS = 'MB_240Cards',
  MB_241CARDS = 'MB_241Cards',
  MB_242CARDS = 'MB_242Cards',
  MB_243CARDS = 'MB_243Cards',
  MB_244CARDS = 'MB_244Cards',
  MB_245CARDS = 'MB_245Cards',
  MB_246CARDS = 'MB_246Cards',
  MB_247CARDS = 'MB_247Cards',
  MB_248CARDS = 'MB_248Cards',
  MB_249CARDS = 'MB_249Cards',
  MB_250CARDS = 'MB_250Cards',
  MB_251CARDS = 'MB_251Cards',
  MB_252CARDS = 'MB_252Cards',
  MB_253CARDS = 'MB_253Cards',
  MB_254CARDS = 'MB_254Cards',
  MB_255CARDS = 'MB_255Cards',
  MB_256CARDS = 'MB_256Cards',
  MB_257CARDS = 'MB_257Cards',
  MB_258CARDS = 'MB_258Cards',
  MB_259CARDS = 'MB_259Cards',
  MB_260CARDS = 'MB_260Cards',
  MB_261CARDS = 'MB_261Cards',
  MB_262CARDS = 'MB_262Cards',
  MB_263CARDS = 'MB_263Cards',
  MB_264CARDS = 'MB_264Cards',
  MB_265CARDS = 'MB_265Cards',
  MB_266CARDS = 'MB_266Cards',
  MB_267CARDS = 'MB_267Cards',
  MB_268CARDS = 'MB_268Cards',
  MB_269CARDS = 'MB_269Cards',
  MB_270CARDS = 'MB_270Cards',
  MB_271CARDS = 'MB_271Cards',
  MB_272CARDS = 'MB_272Cards',
  MB_273CARDS = 'MB_273Cards',
  MB_274CARDS = 'MB_274Cards',
  MB_275CARDS = 'MB_275Cards',
  MB_276CARDS = 'MB_276Cards',
  MB_277CARDS = 'MB_277Cards',
  MB_278CARDS = 'MB_278Cards',
  MB_279CARDS = 'MB_279Cards',
  MB_280CARDS = 'MB_280Cards',
  MB_281CARDS = 'MB_281Cards',
  MB_282CARDS = 'MB_282Cards',
  MB_283CARDS = 'MB_283Cards',
  MB_284CARDS = 'MB_284Cards',
  MB_285CARDS = 'MB_285Cards',
  MB_286CARDS = 'MB_286Cards',
  MB_287CARDS = 'MB_287Cards',
  MB_288CARDS = 'MB_288Cards',
  MB_289CARDS = 'MB_289Cards',
  MB_290CARDS = 'MB_290Cards',
  MB_291CARDS = 'MB_291Cards',
  MB_292CARDS = 'MB_292Cards',
  MB_293CARDS = 'MB_293Cards',
  MB_294CARDS = 'MB_294Cards',
  MB_295CARDS = 'MB_295Cards',
  MB_296CARDS = 'MB_296Cards',
  MB_297CARDS = 'MB_297Cards',
  MB_298CARDS = 'MB_298Cards',
  MB_299CARDS = 'MB_299Cards',
  MB_300CARDS = 'MB_300Cards',
  MB_301CARDS = 'MB_301Cards',
  MB_302CARDS = 'MB_302Cards',
  MB_303CARDS = 'MB_303Cards',
  MB_304CARDS = 'MB_304Cards',
  MB_305CARDS = 'MB_305Cards',
  MB_306CARDS = 'MB_306Cards',
  MB_307CARDS = 'MB_307Cards',
  MB_308CARDS = 'MB_308Cards',
  MB_309CARDS = 'MB_309Cards',
  MB_310CARDS = 'MB_310Cards',
  MB_311CARDS = 'MB_311Cards',
  MB_312CARDS = 'MB_312Cards',
  MB_313CARDS = 'MB_313Cards',
  MB_314CARDS = 'MB_314Cards',
  MB_315CARDS = 'MB_315Cards',
  MB_316CARDS = 'MB_316Cards',
  MB_317CARDS = 'MB_317Cards',
  MB_318CARDS = 'MB_318Cards',
  MB_319CARDS = 'MB_319Cards',
  MB_320CARDS = 'MB_320Cards',
  MB_321CARDS = 'MB_321Cards',
  MB_322CARDS = 'MB_322Cards',
  MB_323CARDS = 'MB_323Cards',
  MB_324CARDS = 'MB_324Cards',
  MB_325CARDS = 'MB_325Cards',
  MB_326CARDS = 'MB_326Cards',
  MB_327CARDS = 'MB_327Cards',
  MB_328CARDS = 'MB_328Cards',
  MB_329CARDS = 'MB_329Cards',
  MB_330CARDS = 'MB_330Cards',
  MB_331CARDS = 'MB_331Cards',
  MB_332CARDS = 'MB_332Cards',
  MB_333CARDS = 'MB_333Cards',
  MB_334CARDS = 'MB_334Cards',
  MB_335CARDS = 'MB_335Cards',
  MB_336CARDS = 'MB_336Cards',
  MB_337CARDS = 'MB_337Cards',
  MB_338CARDS = 'MB_338Cards',
  MB_339CARDS = 'MB_339Cards',
  MB_340CARDS = 'MB_340Cards',
  MB_341CARDS = 'MB_341Cards',
  MB_342CARDS = 'MB_342Cards',
  MB_343CARDS = 'MB_343Cards',
  MB_344CARDS = 'MB_344Cards',
  MB_345CARDS = 'MB_345Cards',
  MB_346CARDS = 'MB_346Cards',
  MB_347CARDS = 'MB_347Cards',
  MB_348CARDS = 'MB_348Cards',
  MB_349CARDS = 'MB_349Cards',
  MB_350CARDS = 'MB_350Cards',
  MB_351CARDS = 'MB_351Cards',
  MB_352CARDS = 'MB_352Cards',
  MB_353CARDS = 'MB_353Cards',
  MB_354CARDS = 'MB_354Cards',
  MB_355CARDS = 'MB_355Cards',
  MB_356CARDS = 'MB_356Cards',
  MB_357CARDS = 'MB_357Cards',
  MB_358CARDS = 'MB_358Cards',
  MB_359CARDS = 'MB_359Cards',
  MB_360CARDS = 'MB_360Cards',
  MB_361CARDS = 'MB_361Cards',
  MB_362CARDS = 'MB_362Cards',
  MB_363CARDS = 'MB_363Cards',
  MB_364CARDS = 'MB_364Cards',
  MB_365CARDS = 'MB_365Cards',
  MB_366CARDS = 'MB_366Cards',
  MB_367CARDS = 'MB_367Cards',
  MB_368CARDS = 'MB_368Cards',
  MB_369CARDS = 'MB_369Cards',
  MB_370CARDS = 'MB_370Cards',
  MB_371CARDS = 'MB_371Cards',
  MB_372CARDS = 'MB_372Cards',
  MB_373CARDS = 'MB_373Cards',
  MB_374CARDS = 'MB_374Cards',
  MB_375CARDS = 'MB_375Cards',
  MB_376CARDS = 'MB_376Cards',
  MB_377CARDS = 'MB_377Cards',
  MB_378CARDS = 'MB_378Cards',
  MB_379CARDS = 'MB_379Cards',
  MB_380CARDS = 'MB_380Cards',
  MB_381CARDS = 'MB_381Cards',
  MB_382CARDS = 'MB_382Cards',
  MB_383CARDS = 'MB_383Cards',
  MB_384CARDS = 'MB_384Cards',
  MB_385CARDS = 'MB_385Cards',
  MB_386CARDS = 'MB_386Cards',
  MB_387CARDS = 'MB_387Cards',
  MB_388CARDS = 'MB_388Cards',
  MB_389CARDS = 'MB_389Cards',
  MB_390CARDS = 'MB_390Cards',
  MB_391CARDS = 'MB_391Cards',
  MB_392CARDS = 'MB_392Cards',
  MB_393CARDS = 'MB_393Cards',
  MB_394CARDS = 'MB_394Cards',
  MB_395CARDS = 'MB_395Cards',
  MB_396CARDS = 'MB_396Cards',
  MB_397CARDS = 'MB_397Cards',
  MB_398CARDS = 'MB_398Cards',
  MB_399CARDS = 'MB_399Cards',
  MB_400CARDS = 'MB_400Cards',
  BACBO_PLAYER = 'BacBo_Player',
  BACBO_BANKER = 'BacBo_Banker',
  BACBO_TIE = 'BacBo_Tie',
  CT_BONUS1 = 'CT_Bonus1',
  CT_BONUS2 = 'CT_Bonus2',
  CT_BONUS3 = 'CT_Bonus3',
  CT_BONUS4 = 'CT_Bonus4',
  GTH_1 = 'GTH_1',
  GTH_1_PICK_FEE = 'GTH_1_Pick_Fee',
  GTH_2 = 'GTH_2',
  GTH_2_PICK_FEE = 'GTH_2_Pick_Fee',
  GTH_4 = 'GTH_4',
  GTH_4_PICK_FEE = 'GTH_4_Pick_Fee',
  GTH_8 = 'GTH_8',
  GTH_8_PICK_FEE = 'GTH_8_Pick_Fee',
  GTH_20 = 'GTH_20',
  GTH_20_PICK_FEE = 'GTH_20_Pick_Fee',
  GTH_65 = 'GTH_65',
  GTH_65_PICK_FEE = 'GTH_65_Pick_Fee',
  COC_MAIN = 'COC_Main',
  LBJ_LIGHTNINGFEE = 'LBJ_LightningFee',
  LBJ_PLAYLIGHTNING = 'LBJ_PlayLightning',
  LBJ_SPLITLIGHTNING = 'LBJ_SplitLightning',
  LBJ_DOUBLEDOWNLIGHTNING = 'LBJ_DoubleDownLightning',
  BAC_PLAYER_PEEKDOUBLE = 'BAC_Player_PeekDouble',
  BAC_BANKER_PEEKDOUBLE = 'BAC_Banker_PeekDouble',
  BAC_PLAYER_PEEKTRIPLE = 'BAC_Player_PeekTriple',
  BAC_BANKER_PEEKTRIPLE = 'BAC_Banker_PeekTriple',
  BAC_PLAYER_FEE = 'BAC_Player_Fee',
  BAC_BANKER_FEE = 'BAC_Banker_Fee',
  BAC_TIE_FEE = 'BAC_Tie_Fee',
  BAC_PLAYERPAIR_FEE = 'BAC_PlayerPair_Fee',
  BAC_BANKERPAIR_FEE = 'BAC_BankerPair_Fee',
  CC_NORMAL = 'CC_Normal',
  CC_XXXTREME = 'CC_XXXtreme',
  CC_SUPERXXXTREME = 'CC_SuperXXXtreme',
  CC_TOPUP = 'CC_TopUp',
  AB_ANDAR = 'AB_Andar',
  AB_BAHAR = 'AB_Bahar',
  AB_CARDS_1_5 = 'AB_Cards_1_5',
  AB_CARDS_6_10 = 'AB_Cards_6_10',
  AB_CARDS_11_15 = 'AB_Cards_11_15',
  AB_CARDS_16_20 = 'AB_Cards_16_20',
  AB_CARDS_21_25 = 'AB_Cards_21_25',
  AB_CARDS_26_30 = 'AB_Cards_26_30',
  AB_CARDS_31_35 = 'AB_Cards_31_35',
  AB_CARDS_36_40 = 'AB_Cards_36_40',
  AB_CARDS_41_45 = 'AB_Cards_41_45',
  AB_CARDS_46_49 = 'AB_Cards_46_49',
  SBJ_RESPLITONFIRST = 'SBJ_ResplitOnFirst',
  SBJ_RESPLITONSECOND = 'SBJ_ResplitOnSecond',
  SBJ_DOUBLEONFIRSTAFTERRESPLIT = 'SBJ_DoubleOnFirstAfterResplit',
  SBJ_DOUBLEONSECONDAFTERRESPLIT = 'SBJ_DoubleOnSecondAfterResplit',
  SBJ_DOUBLEONTHIRDAFTERRESPLIT = 'SBJ_DoubleOnThirdAfterResplit',
  SBJ_DOUBLEONFOURTHAFTERRESPLIT = 'SBJ_DoubleOnFourthAfterResplit',
  TP_ANTEBET = 'TP_AnteBet',
  TP_CALLBET = 'TP_CallBet',
  TP_PAIRBONUSBET = 'TP_PairBonusBet',
  TP_SIXCARDBONUSBET = 'TP_SixCardBonusBet',
  GBROU_0GREEN = 'GBROU_0Green',
  GBROU_1RED = 'GBROU_1Red',
  GBROU_2BLACK = 'GBROU_2Black',
  GBROU_3RED = 'GBROU_3Red',
  GBROU_4BLACK = 'GBROU_4Black',
  GBROU_5RED = 'GBROU_5Red',
  GBROU_6BLACK = 'GBROU_6Black',
  GBROU_7RED = 'GBROU_7Red',
  GBROU_8BLACK = 'GBROU_8Black',
  GBROU_9RED = 'GBROU_9Red',
  GBROU_10BLACK = 'GBROU_10Black',
  GBROU_11BLACK = 'GBROU_11Black',
  GBROU_12RED = 'GBROU_12Red',
  GBROU_13BLACK = 'GBROU_13Black',
  GBROU_14RED = 'GBROU_14Red',
  GBROU_15BLACK = 'GBROU_15Black',
  GBROU_16RED = 'GBROU_16Red',
  GBROU_17BLACK = 'GBROU_17Black',
  GBROU_18RED = 'GBROU_18Red',
  GBROU_19RED = 'GBROU_19Red',
  GBROU_20BLACK = 'GBROU_20Black',
  GBROU_21RED = 'GBROU_21Red',
  GBROU_22BLACK = 'GBROU_22Black',
  GBROU_23RED = 'GBROU_23Red',
  GBROU_24BLACK = 'GBROU_24Black',
  GBROU_25RED = 'GBROU_25Red',
  GBROU_26BLACK = 'GBROU_26Black',
  GBROU_27RED = 'GBROU_27Red',
  GBROU_28BLACK = 'GBROU_28Black',
  GBROU_29BLACK = 'GBROU_29Black',
  GBROU_30RED = 'GBROU_30Red',
  GBROU_31BLACK = 'GBROU_31Black',
  GBROU_32RED = 'GBROU_32Red',
  GBROU_33BLACK = 'GBROU_33Black',
  GBROU_34RED = 'GBROU_34Red',
  GBROU_35BLACK = 'GBROU_35Black',
  GBROU_36RED = 'GBROU_36Red',
  MBB_CHANCECARD1 = 'MBB_ChanceCard1',
  MBB_FREESPACECARD1 = 'MBB_FreeSpaceCard1',
  MBB_CHANCECARD2 = 'MBB_ChanceCard2',
  MBB_FREESPACECARD2 = 'MBB_FreeSpaceCard2',
  MBB_CHANCECARD3 = 'MBB_ChanceCard3',
  MBB_FREESPACECARD3 = 'MBB_FreeSpaceCard3',
  MBB_CHANCECARD4 = 'MBB_ChanceCard4',
  MBB_FREESPACECARD4 = 'MBB_FreeSpaceCard4',
  MBB_3ROLLS = 'MBB_3Rolls',
  MBB_5ROLLS = 'MBB_5Rolls',
  TD_A = 'TD_A',
  TD_B = 'TD_B',
  TD_X = 'TD_X',
  DAS_2_CLUBS = 'DAS_2_Clubs',
  DAS_3_CLUBS = 'DAS_3_Clubs',
  DAS_4_CLUBS = 'DAS_4_Clubs',
  DAS_5_CLUBS = 'DAS_5_Clubs',
  DAS_6_CLUBS = 'DAS_6_Clubs',
  DAS_7_CLUBS = 'DAS_7_Clubs',
  DAS_8_CLUBS = 'DAS_8_Clubs',
  DAS_9_CLUBS = 'DAS_9_Clubs',
  DAS_10_CLUBS = 'DAS_10_Clubs',
  DAS_JACK_CLUBS = 'DAS_Jack_Clubs',
  DAS_QUEEN_CLUBS = 'DAS_Queen_Clubs',
  DAS_KING_CLUBS = 'DAS_King_Clubs',
  DAS_ACE_CLUBS = 'DAS_Ace_Clubs',
  DAS_2_DIAMONDS = 'DAS_2_Diamonds',
  DAS_3_DIAMONDS = 'DAS_3_Diamonds',
  DAS_4_DIAMONDS = 'DAS_4_Diamonds',
  DAS_5_DIAMONDS = 'DAS_5_Diamonds',
  DAS_6_DIAMONDS = 'DAS_6_Diamonds',
  DAS_7_DIAMONDS = 'DAS_7_Diamonds',
  DAS_8_DIAMONDS = 'DAS_8_Diamonds',
  DAS_9_DIAMONDS = 'DAS_9_Diamonds',
  DAS_10_DIAMONDS = 'DAS_10_Diamonds',
  DAS_JACK_DIAMONDS = 'DAS_Jack_Diamonds',
  DAS_QUEEN_DIAMONDS = 'DAS_Queen_Diamonds',
  DAS_KING_DIAMONDS = 'DAS_King_Diamonds',
  DAS_ACE_DIAMONDS = 'DAS_Ace_Diamonds',
  DAS_2_HEARTS = 'DAS_2_Hearts',
  DAS_3_HEARTS = 'DAS_3_Hearts',
  DAS_4_HEARTS = 'DAS_4_Hearts',
  DAS_5_HEARTS = 'DAS_5_Hearts',
  DAS_6_HEARTS = 'DAS_6_Hearts',
  DAS_7_HEARTS = 'DAS_7_Hearts',
  DAS_8_HEARTS = 'DAS_8_Hearts',
  DAS_9_HEARTS = 'DAS_9_Hearts',
  DAS_10_HEARTS = 'DAS_10_Hearts',
  DAS_JACK_HEARTS = 'DAS_Jack_Hearts',
  DAS_QUEEN_HEARTS = 'DAS_Queen_Hearts',
  DAS_KING_HEARTS = 'DAS_King_Hearts',
  DAS_ACE_HEARTS = 'DAS_Ace_Hearts',
  DAS_2_SPADES = 'DAS_2_Spades',
  DAS_3_SPADES = 'DAS_3_Spades',
  DAS_4_SPADES = 'DAS_4_Spades',
  DAS_5_SPADES = 'DAS_5_Spades',
  DAS_6_SPADES = 'DAS_6_Spades',
  DAS_7_SPADES = 'DAS_7_Spades',
  DAS_8_SPADES = 'DAS_8_Spades',
  DAS_9_SPADES = 'DAS_9_Spades',
  DAS_10_SPADES = 'DAS_10_Spades',
  DAS_JACK_SPADES = 'DAS_Jack_Spades',
  DAS_QUEEN_SPADES = 'DAS_Queen_Spades',
  DAS_KING_SPADES = 'DAS_King_Spades',
  DAS_ACE_SPADES = 'DAS_Ace_Spades',

  // simpleplay
  SLOT_SPIN = 'Slot_Spin',
  FISH_SHOOT = 'Fish_Shoot',
  TABLE = 'Table',
  BACCARAT_0 = 'Baccarat_0',
  BACCARAT_1 = 'Baccarat_1',
  BACCARAT_2 = 'Baccarat_2',
  BACCARAT_3 = 'Baccarat_3',
  BACCARAT_4 = 'Baccarat_4',
  BACCARAT_25 = 'Baccarat_25',
  BACCARAT_26 = 'Baccarat_26',
  BACCARAT_27 = 'Baccarat_27',
  BACCARAT_28 = 'Baccarat_28',
  BACCARAT_29 = 'Baccarat_29',
  BACCARAT_53 = 'Baccarat_53',
  BACCARAT_54 = 'Baccarat_54',
  COLOR_SICBO_1 = 'Color_Sicbo_1',
  COLOR_SICBO_2 = 'Color_Sicbo_2',
  COLOR_SICBO_3 = 'Color_Sicbo_3',
  COLOR_SICBO_4 = 'Color_Sicbo_4',
  COLOR_SICBO_5 = 'Color_Sicbo_5',
  COLOR_SICBO_6 = 'Color_Sicbo_6',
  SHAKE_SHAKE_0 = 'Shake_Shake_0',
  SHAKE_SHAKE_1 = 'Shake_Shake_1',
  SHAKE_SHAKE_2 = 'Shake_Shake_2',
  PULA_PUTI_0 = 'Pula_Puti_0',
  PULA_PUTI_1 = 'Pula_Puti_1',
  PULA_PUTI_2 = 'Pula_Puti_2',
  PULA_PUTI_3 = 'Pula_Puti_3',
  PULA_PUTI_4 = 'Pula_Puti_4',
  PULA_PUTI_5 = 'Pula_Puti_5',

  // microgaming
  SLOTS = 'SLOTS',

  // CFD
  CFD_MARKET_ORDER_OPEN = 'CFD_MARKET_ORDER_OPEN',
  CFD_MARKET_ORDER_CLOSE = 'CFD_MARKET_ORDER_CLOSE',
  CFD_LIMIT_PRICE_STOP_ORDER = 'CFD_LIMIT_PRICE_STOP_ORDER',
  CFD_STOP_LOSS_STOP_ORDER = 'CFD_STOP_LOSS_STOP_ORDER',
  CFD_LIMIT_PRICE_OPEN = 'CFD_LIMIT_PRICE_OPEN',

  // Binary Option
  UP = 'UP',
  DOWN = 'DOWN',
  TIE = 'TIE',
}
