export type TelegramSetting = {
  enabled: boolean;
  chatId: number;
  username: string;
};

export type LineSetting = {
  enabled: boolean;
  userId: string;
};

export type NotificationChannelSetting = {
  telegram: TelegramSetting;
  line: LineSetting;
};

export enum NotificationChannel {
  telegram = 'telegram',
  line = 'line',
  whatsapp = 'whatsapp',
}
