export enum LotteryUserTransactionStatus {
  ENROLLED = 'ENROLLED',
  REFUNDED = 'REFUNDED',
  WON = 'WON',
  AUTO_ENROLLMENT = 'AUTO_ENROLLMENT',
}

export enum LotteryNftResultStatus {
  WON = 'WON',
  NOT_SOLD = 'NOT_SOLD',
  NOT_START = 'NOT_START',
}

export enum AUTO_ENTRY_STATUS {
  SUCCESS = 'SUCCESS',
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  SMALLER_THAN_CURRENT_BID = 'SMALLER_THAN_CURRENT_BID',
}
