import type { ThemeConfig } from 'antd';

export const themeMode = {
  light: 'light',
  dark: 'dark',
} as const;

export type ThemeModeType = typeof themeMode[keyof typeof themeMode];

export type CPThemeConfig = ThemeConfig & {
  token: ThemeConfig['token'] & {
    colorBgBody?: string;
    colorBgFooter?: string;
    colorInfoGradient?: string;
    colorBgLanguageDropDown?: string;
    colorFooterText?: string;
    colorFooterBorder?: string;
    colorInfoLight?: string;
    colorTip?: string;
    colorNotificationHover?: string;
    fontSizeXS?: number;
    fontSizeXXL?: number;
    fontSizeXXXL?: number;
    colorPrimaryButtonBg?: string;
    colorLogoutBg?: string;
  };
  components: ThemeConfig['components'] & {
    Hero?: {
      colorBgContainer?: string;
    };
    Tabs?: ThemeConfig['components']['Tabs'] & {
      colorBgActive?: string;
      colorBgDisabled?: string;
    };
    Card?: ThemeConfig['components']['Card'] & {
      colorBgContainerPlain?: string;
      colorLiveBorder?: string;
    };
  };
};

const commonTheme: CPThemeConfig = {
  token: {
    fontFamily: "'MontserratVariable', sans-serif",
    fontSizeHeading1: 56,
    fontSizeHeading2: 48,
    fontSizeHeading3: 40,
    fontSizeHeading4: 32,
    fontSizeHeading5: 24,
    lineHeightHeading1: 1.25,
    lineHeightHeading2: 1.25,
    lineHeightHeading3: 1.25,
    lineHeightHeading4: 1.25,
    lineHeightHeading5: 1.25,
    fontWeightStrong: 900,
    lineHeight: 1.5,
    fontSizeXS: 12,
    fontSizeSM: 14,
    fontSize: 16,
    fontSizeLG: 18,
    fontSizeXL: 24,
    fontSizeXXL: 32,
    fontSizeXXXL: 40,
    fontSizeIcon: 20,
    borderRadius: 60,
    borderRadiusLG: 24,
    borderRadiusSM: 16,
    borderRadiusXS: 10,
    sizeXXS: 4,
    sizeXS: 8,
    sizeSM: 12,
    size: 16,
    sizeMD: 16,
    sizeLG: 24,
    sizeXL: 32,
    sizeXXL: 48,
    colorWhite: '#FFFFFF',
    colorBgLayout: 'transparent',
  },
  components: {
    Button: {
      controlHeight: 48,
      controlHeightSM: 38,
      paddingContentHorizontal: 30,
      paddingContentHorizontalSM: 30,
    },
    Form: {
      borderRadius: 60,
      paddingSM: 20,
    },
    Modal: {
      paddingContentHorizontalLG: 48,
      paddingMD: 48,
    },
    Tabs: {
      fontFamily: '"NunitoVariable", sans-serif',
      margin: 32,
      fontSize: 20,
      fontSizeSM: 18,
    },
    Card: {
      paddingLG: 48,
      colorBorder: '#FFFFFF',
    },
    Steps: {
      controlHeight: 36,
      lineHeight: 1.5,
      margin: 24,
    },
    Select: {
      fontWeightStrong: 600,
    },
    Input: {
      fontSize: 16,
      lineHeight: 1.5,
      paddingContentVertical: 12,
      paddingContentHorizontal: 24,
      borderRadius: 24,
    },
  },
};

export const lightTheme: CPThemeConfig = {
  ...commonTheme,
  token: {
    ...commonTheme.token,
    colorBgBody: '#FFFFFF',
    colorError: '#EA1F6E',
    colorPrimary: '#f97d9f',
    colorPrimaryBorder: '#f97d9f',
    colorPrimaryHover: '#FB97B2',
    colorTextBase: '#262424',
    colorText: '#262424',
    colorTextDisabled: '#8D8D8D',
    colorLogoutBg: 'rgba(38, 38, 36, 0.06)',
    colorPrimaryButtonBg: '#FFFFFF',
    colorBgContainer: '#FFFFFF',
    colorBgLanguageDropDown: '#FFFFFF',
    colorNotificationHover: '#f7f7f7',
    colorBgContainerDisabled: '#F5F5F5',
    colorFooterText: '#262424',
    colorFooterBorder: 'rgba(5, 5, 5, 0.06)',
    colorInfo: '#61ccc7',
    colorTip: '#262425',
    colorInfoHover: '#81D8D0',
    colorSuccess: '#00A9AD',
    colorSuccessHover: '#81D8D0',
    colorInfoLight: '#DCFEFF',
    colorTextHeading: '#f97d9f',
    colorLink: '#262424',
    colorLinkHover: '#f97d9f',
    colorBorder: '#DBDBDB',
    boxShadowTertiary: '0px 2px 10px 0px #0000001A',
    colorBgFooter:
      'linear-gradient(180deg, #DCFEFF 0%, #AFEBE8 50%, #81D8D0 100%)',
    colorInfoGradient:
      'linear-gradient(180deg, #61CCC7 0%, #81D8D0 48.44%, #98E2DC 100%)',
  },
  components: {
    ...commonTheme.components,
    Input: {
      colorBgContainer: '#ffffff',
      colorBgContainerDisabled: '#DBDBDB',
    },
    Menu: {
      ...commonTheme.components.Menu,
      colorBgBase: '#B1EFEE',
      colorItemText: '#8D8D8D',
    },
    Modal: {
      ...commonTheme.components.Modal,
      colorBgContainer: '#FFFFFF',
    },
    Tabs: {
      ...commonTheme.components.Tabs,
      colorBgContainer: '#FFE4EC',
      colorBgActive: '#f97d9f',
      colorBgDisabled: '#DBDBDB',
      colorBgBase: 'rgba(255, 255, 255, 0.80)',
      colorTextBase: '#262424',
    },
    Card: {
      ...commonTheme.components.Card,
      colorBgContainer: '#ffffff',
      colorBgContainerPlain: '#ffffff',
      colorBorder: '#DBDBDB',
      boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
      boxShadowTertiary: '0px 6px 12px 0px rgba(0, 0, 0, 0.15)',
      colorLiveBorder: '#EF3F7E',
    },
    Hero: {
      colorBgContainer: '#61ccc7',
    },
    Drawer: {
      colorBgElevated: '#ffffff',
    },
    Table: {
      colorFillAlter: '#FFFFFF',
      colorTextHeading: '#262424',
    },
  },
};

export const darkTheme: CPThemeConfig = {
  ...commonTheme,
  token: {
    ...commonTheme.token,
    colorBgBody: '#0f0043',
    colorError: '#EA1F6E',
    colorPrimary: '#f97d9f',
    colorPrimaryBorder: '#f97d9f',
    colorPrimaryHover: '#FB97B2',
    colorTextBase: '#ffffff',
    colorText: '#ffffff',
    colorTextDisabled: '#8D8D8D',
    colorFooterText: '#F5F5F5',
    colorFooterBorder: 'rgba(254, 255, 252, 0.94)',
    colorLogoutBg: 'rgba(255, 255, 255, 0.06)',
    colorPrimaryButtonBg: 'transparent',
    colorNotificationHover: '#13006F',
    colorBgContainer: '#0E0035',
    colorBgLanguageDropDown: '#120060',
    colorBgContainerDisabled: '#120060',
    colorInfo: '#61ccc7',
    colorTip: '#C1C1C1',
    colorInfoHover: '#81D8D0',
    colorSuccess: '#00A9AD',
    colorSuccessHover: '#81D8D0',
    colorInfoLight: '#DCFEFF',
    colorTextHeading: '#f97d9f',
    colorLink: '#262424',
    colorLinkHover: '#f97d9f',
    colorBorder: '#DBDBDB',
    boxShadowTertiary: '0px 2px 10px 0px #0000001A',
    colorBgFooter:
      'linear-gradient(179.93deg, #13006F 0.06%, #100052 51.04%, #0F0043 99.94%)',
    colorInfoGradient:
      'linear-gradient(179.89deg, #16008B 0.1%, #13006F 96.57%)',
  },
  components: {
    ...commonTheme.components,
    Input: {
      colorBgContainer: '#13006F',
      colorBgContainerDisabled: '#120060',
    },
    Menu: {
      ...commonTheme.components.Menu,
      colorBgBase: '#B1EFEE',
      colorItemText: '#C1C1C1',
    },
    Modal: {
      ...commonTheme.components.Modal,
      colorBgContainer: 'linear-gradient(180deg, #16008B 0%, #13006F 100%)',
    },
    Tabs: {
      ...commonTheme.components.Tabs,
      colorBgContainer: '#262424',
      colorBgActive: '#f97d9f',
      colorBgDisabled: '#DBDBDB',
      colorBgBase: '#C1C1C1',
      colorTextBase: '#262424',
    },
    Card: {
      ...commonTheme.components.Card,
      colorBgContainer: 'linear-gradient(180deg, #16008B 0%, #13006F 100%)',
      colorBgContainerPlain: '#0E0035',
      colorBorder: '#DBDBDB',
      boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
      boxShadowTertiary: '0px 6px 10px 0px rgba(24, 0, 168, 0.20)',
      colorLiveBorder: '#EF3F7E',
    },
    Hero: {
      colorBgContainer: '#0E0035',
    },
    Drawer: {
      colorBgElevated: '#0E0035',
    },
    Select: {
      colorBgElevated: '#120060',
    },
    Table: {
      colorBgContainer: 'transparent',
      colorFillAlter: 'transparent',
      colorTextHeading: '#ffffff',
    },
  },
};
