import { datadogRum } from '@datadog/browser-rum';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { AnimatePresence } from 'framer-motion';
import { getCookie, setCookie } from 'cookies-next';
import Image from 'next/image';
import Script from 'next/script';

import '@fontsource/nunito/variable.css';
import '@fontsource/nunito/variable-italic.css';
import '@fontsource/montserrat/variable.css';
import '@fontsource/montserrat/variable-italic.css';

import 'antd/dist/reset.css';
import '../public/styles/index.scss';
import '../public/styles/vars.css';
import type { ThemeModeType } from '../constant/themes';
import { darkTheme, lightTheme, themeMode } from '../constant/themes';
import { useEffect, useState } from 'react';
import { ThemeModeContext } from '../libs/hooks/useThemeMode';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

const StyledBg = styled.div({
  position: 'absolute',
  width: '100%',
  minWidth: '1350px',
  left: '50%',
  transform: 'translate(-50%, -20%)',
  [media.md]: {
    minWidth: '1438px',
    transform: 'translate(-50%, -4%)',
  },
});

const StyledInnerBg = styled.div({
  position: 'relative',
});

const StyledCloud = styled.div({
  position: 'absolute',
  bottom: 0,
  width: '100%',
});

const StyledVideo = styled.div({
  display: 'none',
  [media.md]: {
    display: 'block',
  },
});

const StyledMobileVideo = styled.div({
  display: 'block',
  [media.md]: {
    display: 'none',
  },
});

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID || '',
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: 'candypig-backend',
  env: process.env.NEXT_PUBLIC_ENV || '',
  allowedTracingUrls: [`${process.env.NEXT_PUBLIC_BASE_URL}/api`],

  // Specify a version number to identify the deployed version of your application in Datadog
  version: '0.0.1',
  sampleRate: 100,
  premiumSampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

function CustomApp({ Component, pageProps }: AppProps) {
  const [selectedThemeMode, setSelectedThemeMode] = useState<ThemeModeType>(
    themeMode['light']
  );
  const router = useRouter();
  const { pathname } = router;

  const isLight = selectedThemeMode === themeMode.light;

  const currentTheme = isLight ? lightTheme : darkTheme;

  const setCurrentThemeAndSave = (theme: ThemeModeType) => {
    setSelectedThemeMode(theme);
    setCookie('CP_theme', theme);
  };

  useEffect(() => {
    const cookie_theme =
      getCookie('CP_theme') && getCookie('CP_theme').toString();
    if (cookie_theme) setCurrentThemeAndSave(cookie_theme as ThemeModeType);
  }, []);

  const videoUrl =
    selectedThemeMode === themeMode.light
      ? '/videos/home_background.mp4'
      : '/videos/home_background-dark.mp4';

  const cloudUrl =
    selectedThemeMode === themeMode.light
      ? '/images/landing/cloud.png'
      : '/images/landing/cloud-dark.png';

  const videoMobileUrl =
    selectedThemeMode === themeMode.light
      ? '/videos/home_background_mobile.mp4'
      : '/videos/home_background_mobile-dark.mp4';

  return (
    <ThemeModeContext.Provider
      value={{
        selectedThemeMode,
        setCurrentThemeAndSave,
      }}
    >
      <AntdConfigProvider theme={currentTheme}>
        <EmotionThemeProvider theme={currentTheme}>
          <GoogleReCaptchaProvider
            reCaptchaKey={
              process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SECRET_KEY || ''
            }
          >
            <Head>
              <link rel="shortcut icon" href="/images/favicon/favicon.ico" />
              <link
                rel="icon"
                type="image/png"
                sizes="192x192"
                href="/images/favicon/android-chrome-192x192.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/images/favicon/favicon-16x16.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/images/favicon/favicon-32x32.png"
              />
              <link
                rel="apple-touch-icon"
                href="/images/favicon/apple-touch-icon.png"
              />
            </Head>
            {/*@TODO: slightly hacking bgimg here -- perhaps come up with a better solution*/}
            <AnimatePresence mode="wait" initial={false}>
              <main
                className="app"
                style={{
                  backgroundColor: isLight ? '#ffffff' : '#0f0043',
                  overflow: 'clip',
                }}
              >
                {pathname === '/' && (
                  <>
                    <StyledBg>
                      <StyledInnerBg>
                        <StyledVideo>
                          <ReactPlayer
                            url={videoUrl}
                            muted
                            loop
                            playing
                            width="100%"
                            height="100%"
                            playsinline
                          />
                        </StyledVideo>
                        <StyledMobileVideo>
                          <ReactPlayer
                            url={videoMobileUrl}
                            muted
                            loop
                            playing
                            width="100%"
                            height="100%"
                            playsinline
                          />
                        </StyledMobileVideo>
                        <StyledCloud>
                          <Image
                            src={cloudUrl}
                            alt="cloud"
                            sizes="100vw"
                            style={{ width: '100%', height: 'auto' }}
                            width="1728"
                            height="237"
                          />
                        </StyledCloud>
                      </StyledInnerBg>
                    </StyledBg>
                  </>
                )}
                {pathname !== '/' && (
                  <Image
                    src={`/images/bg_header${isLight ? '' : '_dark_2'}.png`}
                    alt="background"
                    fill
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: null,
                    }}
                  />
                )}
                <Component {...pageProps} />
                <Script
                  src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
                />
                <Script id="google-analytics">
                  {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', '${
                      process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID
                    }' ${
                    process.env.NODE_ENV !== 'production'
                      ? `, { 'debug_mode': true }`
                      : ''
                  } );
                  `}
                </Script>
              </main>
            </AnimatePresence>
          </GoogleReCaptchaProvider>
        </EmotionThemeProvider>
      </AntdConfigProvider>
    </ThemeModeContext.Provider>
  );
}

export default appWithTranslation(CustomApp);
