export enum Currency {
  USDT_ERC20 = 'USDT_ERC20',
  USDT_TRC20 = 'USDT_TRC20',
  USDT_OMNI = 'USDT_OMNI',
  USDT_BEP20 = 'USDT_BEP20',
  ETH = 'ETH',
  NFT_AKASHIC = 'NFT_AKASHIC',
  USDT_AKASHIC = 'USDT_AKASHIC',
}

export enum Network {
  ERC20 = 'ERC20',
  TRC20 = 'TRC20',
  USDT_AKASHIC = 'USDT_AKASHIC',
}

export enum Layer {
  LAYER1 = 1,
  LAYER2 = 2,
}
