export enum GameGroup {
  BLACKANTS = 'blackants',
}

export enum GameSite {
  CFD = 'cfd',
  EVOLUTION = 'evolution',
  SIMPLEPLAY = 'simpleplay',
  MICROGAMING = 'microgaming',
  CANDYWORLD = 'candyworld',
  BINARYOPTION = 'binaryoption',
}

export const GameSiteSettleTimezone = [
  { gameSite: GameSite.EVOLUTION, timezone: 0 },
  { gameSite: GameSite.SIMPLEPLAY, timezone: 0 },
  { gameSite: GameSite.CFD, timezone: 0 },
  { gameSite: GameSite.CANDYWORLD, timezone: 0 },
  { gameSite: GameSite.MICROGAMING, timezone: 0 },
  { gameSite: GameSite.BINARYOPTION, timezone: 0 },
];
