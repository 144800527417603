export const media = {
  xs: '@media (min-width: 375px)',
  sm: '@media (min-width: 576px)',
  md: '@media (min-width: 768px)',
  lg: '@media (min-width: 992px)',
  xl: '@media (min-width: 1200px)',
  xxl: '@media (min-width: 1600px)',
  sm_max: '@media (max-width: 575px)',
  md_max: '@media (max-width: 767px)',
  lg_max: '@media (max-width: 991px)',
  xl_max: '@media (max-width: 1199px)',
  xxl_max: '@media (max-width: 1599px)',
  landscape: '@media screen and (orientation: landscape)',
  portrait: ' @media screen and (orientation: portrait)',
};
