export enum ApiError {
  EMAIL_ALREADY_EXIST = 'EMAIL_ALREADY_EXIST',
  INCORRECT_EMAIL_FORMAT = 'INCORRECT_EMAIL_FORMAT',
  INCORRECT_ALIAS_FORMAT = 'INCORRECT_ALIAS_FORMAT',
  USERNAME_ALREADY_EXIST = 'USERNAME_ALREADY_EXIST',
  INCORRECT_CREDENTIALS = 'INCORRECT_CREDENTIALS',
  INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
  VERIFICATION_FAILURE = 'VERIFICATION_FAILURE',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  EXCEED_MAX_DATE_RANGE = 'EXCEED_MAX_DATE_RANGE',
  INVALID_USER_TYPE = 'INVALID_USER_TYPE',
  NOT_FOUND_REFERRER = 'NOT_FOUND_REFERRER',
  USER_NOT_EXIST = 'USER_NOT_EXIST',
  USER_DISABLED = 'USER_DISABLED',
  USER_BETTING_DISABLED = 'USER_BETTING_DISABLED',
  OTP_EMPTY = 'OTP_EMPTY',
}

export enum PaymentError {
  UNSUPPORTED_PAYMENT = 'UNSUPPORTED_PAYMENT',
  DUPLICATED_PAYMENT_REQUEST = 'DUPLICATED_PAYMENT_REQUEST',
  INVALID_SIGNATURE = 'INVALID_SIGNATURE',
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  PAYMENT_SETTLED = 'PAYMENT_SETTLED',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  INVALID_STATUS = 'INVALID_STATUS',
}

export enum NftError {
  ALREADY_REGISTERED = 'ALREADY_REGISTERED',
  INVALID_NFT_TYPE = 'INVALID_NFT_TYPE',
  INVALID_NFT_STATUS = 'INVALID_NFT_STATUS',
  REACH_MAX_NFT_COUNT_IN_LOTTERY = 'REACH_MAX_NFT_COUNT_IN_LOTTERY',
  UNMATCH_OWNER = 'UNMATCH_OWNER',
  SAME_OWNER = 'SAME_OWNER',
  CAN_SELL_ONE_NFT_PER_24H = 'CAN_SELL_ONE_NFT_PER_24H',
  NFT_ALREADY_COLLATERALIZED = 'NFT_ALREADY_COLLATERALIZED',
}

export enum LoanError {
  INVALID_LOAN_TYPE = 'INVALID_LOAN_TYPE',
  EXPIRED = 'EXPIRED',
}

export enum AuctionError {
  AUCTION_NOT_FOUND = 'AUCTION_NOT_FOUND',
  AUCTION_ENDED = 'AUCTION_ENDED',
  SAME_OWNER = 'SAME_OWNER',
  INCORRECT_AMOUNT = 'INCORRECT_AMOUNT',
}

export enum UserWalletError {
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  WALLET_NOT_FOUND = 'WALLET_NOT_FOUND',
}

export enum LotteryError {
  LOTTERY_NOT_EXIST = 'LOTTERY_NOT_EXIST',
  LOTTERY_EXPIRED = 'LOTTERY_EXPIRED',
  LOTTERY_ENDED = 'LOTTERY_ENDED',
  LOTTERY_ERROR = 'LOTTERY_ERROR',
  ALREADY_PARTICIPATED_TO_THIS_LOTTERY = 'ALREADY_PARTICIPATED_TO_THIS_LOTTERY',
  NOT_PARTICIPATED_TO_THIS_LOTTERY = 'NOT_PARTICIPATED_TO_THIS_LOTTERY',
  INVALID_NEW_ENTRY_AMOUNT = 'INVALID_NEW_ENTRY_AMOUNT',
  LOTTERY_CONTAIN_NO_NFT = 'LOTTERY_CONTAIN_NO_NFT',
  CANNOT_LIST_TO_LIVE_LOTTERY = 'CANNOT_LIST_TO_LIVE_LOTTERY',
  CANNOT_AUTO_ENROLL_IN_UPCOMING_LOTTERY = 'CANNOT_AUTO_ENROLL_IN_UPCOMING_LOTTERY',
  INVALID_LOTTERY_STATUS = 'INVALID_LOTTERY_STATUS',
  AMOUNT_SHOULD_BE_GREATER_THAN = 'AMOUNT_SHOULD_BE_GREATER_THAN',
  AMOUNT_SHOULD_BE_LESS_THAN = 'AMOUNT_SHOULD_BE_LESS_THAN',
  AMOUNT_EMPTY = 'AMOUNT_EMPTY',
}

export enum LotteryResultGenerateError {
  INVALID_WINNER = 'INVALID_WINNER',
  NO_PENDING_LIVE_LOTTERY = 'NO_PENDING_LIVE_LOTTERY',
}

export enum WithdrawError {
  ADDRESS_ALREADY_EXIST = 'ADDRESS_ALREADY_EXIST',
  WITHDRAW_COUNT_EXCEEDED = 'WITHDRAW_COUNT_EXCEEDED',
  WHITELISTED_ADDRESS_EXCEEDED = 'WHITELISTED_ADDRESS_EXCEEDED',
  WHITELISTED_ADDRESS_NOT_EXIST = 'WHITELISTED_ADDRESS_NOT_EXIST',
  WITHDRAWAL_NOT_EXIST = 'WITHDRAWAL_NOT_EXIST',
  WITHDRAWAL_PAYMENT_NOT_EXIST = 'WITHDRAWAL_PAYMENT_NOT_EXIST',
  WITHDRAWAL_ALREADY_IN_PROGRESS = 'WITHDRAWAL_ALREADY_IN_PROGRESS',
}

export enum HnsRequestError {
  NAME_ALREADY_EXIST = 'NAME_ALREADY_EXIST',
  NAME_ALREADY_SIMILAR = 'NAME_ALREADY_SIMILAR',
  INVALID_STATUS = 'INVALID_STATUS',
}

export enum GameError {
  GAME_NOT_EXIST = 'GAME_NOT_EXIST',
}

export enum AutoEntryError {
  AUTO_ENTRY_SMALLER_THEN_CURRENT_BID = 'AUTO_ENTRY_SMALLER_THEN_CURRENT_BID',
  AUTO_ENTRY_INSUFFICIENT_BALANCE = 'AUTO_ENTRY_INSUFFICIENT_BALANCE',
}
