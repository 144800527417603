export enum UserWalletTransactionType {
  //Name used in UI: Deposit
  DEPOSIT_PAYMENT = 'deposit_payment',
  //Name used in UI: Top Up
  ADMIN_DEPOSIT = 'admin_deposit',
  //Name used in UI: Withdrawal
  WITHDRAWAL = 'withdrawal',
  //Name used in UI: Withdrawal Refunded
  WITHDRAWAL_REFUND = 'withdrawal_refund',
  //Name used in UI: Referral Profit
  REFERRAL_BONUS = 'referral_bonus',

  //Name used in UI: {UI Name}
  LOTTERY_ENROLLMENT = 'lottery_enrollment',
  //Name used in UI: {UI Name}
  LOTTERY_RAISE_ENROLLMENT = 'lottery_raise_enrollment',
  //Name used in UI: {UI Name}
  AUCTION_PURCHASE = 'auction_purchase',
  //Name used in UI: {UI Name}
  AUCTION_SELL = 'auction_sell',
  //Name used in UI: {UI Name}
  AUCTION_BID = 'auction_bid',
  //Name used in UI: {UI Name}
  AUCTION_BID_ADD = 'auction_bid_add',
  //Name used in UI: {UI Name}
  AUCTION_BID_RETURN = 'auction_bid_return',
  //Name used in UI: {UI Name}
  LOTTERY_REFUND = 'lottery_refund',
  //Name used in UI: {UI Name}
  LOTTERY_NFT_SOLD = 'lottery_nft_sold',
  //Name used in UI: {UI Name}
  BET_EVENT = 'bet_event',
  //Name used in UI: {UI Name}
  POINT_COMPENSATE = 'point_compensate',
  //Name used in UI: {UI Name}
  POINT_REWARD = 'point_reward',
  //Name used in UI: {UI Name}
  HNS_REQUEST = 'hns_request',
  //Name used in UI: {UI Name}
  HNS_REQUEST_REFUND = 'hns_request_refund',
  //Name used in UI: {UI Name}
  NFT_LOAN = 'nft_loan',
  //Name used in UI: {UI Name}
  REPAY_LOAN = 'repay_loan',
}
