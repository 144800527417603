export enum NftEventRecordType {
  Gacha = 'Gacha',
  Collection = 'Collection',
}

export enum NftEventType {
  GachaSold = 'Gacha Sold',
  GachaWon = 'Gacha Won',
  GachaRefunded = 'Gacha Refunded',

  AuctionList = 'Auction List',
  AuctionSold = 'Auction Sold',
  AuctionBought = 'Auction Bought',
  AuctionOutbid = 'Auction Outbid',
  AuctionFailed = 'Auction Failed',

  BuySellList = 'BuySell List',
  BuySellSold = 'BuySell Sold',
  BuySellBought = 'BuySell Bought',
  BuySellUpdate = 'BuySell Update',
  BuySellCancel = 'BuySell Cancel',

  TransferIn = 'Transfer In',
  TransferOut = 'Transfer Out',

  LendingBorrow = 'Lending Borrow',
  LendingRepay = 'Lending Repay',
  LendingAutoRepay = 'Lending Auto Repay',

  Collect = 'Collect',
}

export enum NftEventStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface NftEventMetaData {
  type: NftEventType;
}

export interface NftEventTransferOutMetadata extends NftEventMetaData {
  toAddress: string;
}

export interface NftEventTransferInMetadata extends NftEventMetaData {
  fromAddress: string;
}

export interface NftEventCollectMetadata extends NftEventMetaData {
  gachaPrice: number;
}

export interface NftEventGachaMetadata extends NftEventMetaData {
  lotteryBlockNumber: number;
}

export interface NftEventGachaSoldMetadata extends NftEventGachaMetadata {
  lotteryBlockNumber: number;
  purchasedPrice: number;
  lastPrice: number;
  earnedUsdt: number;
  earnedPoint: number;
  fee: number;
}

export interface NftEventGachaBoughtMetadata extends NftEventGachaMetadata {
  lastPrice: number;
  bidPrice: number;
  refundPrice: number;
}

export interface NftEventRefundMetadata extends NftEventGachaMetadata {
  refundPrice: number;
}

export interface NftEventAuctionListMetadata extends NftEventMetaData {
  startPrice: number;
  reservePrice: number;
}

export interface NftEventAuctionFailedMetadata extends NftEventMetaData {
  startPrice: number;
  reservePrice: number;
}

export interface NftEventAuctionOutbidMetadata extends NftEventMetaData {
  bidPrice: number;
}

export interface NftEventAuctionSoldMetadata extends NftEventMetaData {
  price: number;
  fee: number;
  earnedUsdt: number;
}

export interface NftEventAuctionBoughtMetadata extends NftEventMetaData {
  price: number;
}

export interface NftBuySellListMetadata extends NftEventMetaData {
  price: number;
}

export interface UpdateNftBuySellListMetadata extends NftEventMetaData {
  newPrice: number;
}

export interface NftBuySellSoldMetadata extends NftEventMetaData {
  price: number;
  fee: number;
  earnedUsdt: number;
}

export interface NftBuySellBoughtMetadata extends NftEventMetaData {
  price: number;
}

export interface NftBuySellUpdateMetadata extends NftEventMetaData {
  price: number;
}

export interface NftLendingBorrowMetadata extends NftEventMetaData {
  nftPrice: number;
  borrowAmount: number;
  beforeBalance: number;
}

export interface NftLendingRepayMetadata extends NftEventMetaData {
  borrowAmount: number;
  repayFee: number;
  borrowDay: number;
  repayAmount: number;
  beforeBalance: number;
}

export interface NftLendingAutoRepayMetadata extends NftEventMetaData {
  borrowAmount: number;
  repayFee: number;
  borrowDay: number;
  repayAmount: number;
  beforeBalance: number;
}
