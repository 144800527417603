import type { ThemeModeType } from '../../constant/themes';
import { createContext, useContext } from 'react';

export const ThemeModeContext = createContext<{
  selectedThemeMode: ThemeModeType;
  setCurrentThemeAndSave?: (ThemeType) => void;
}>({
  selectedThemeMode: 'light',
  setCurrentThemeAndSave: undefined,
});

export const useThemeMode = () => {
  return useContext(ThemeModeContext);
};
