export enum GameType {
  UNKNOWN = 'unknown',

  // evolution
  BACBO = 'bacbo',
  BACCARAT = 'baccarat',
  ROULETTE = 'roulette',
  AMERICAN_ROULETTE = 'americanroulette',
  INSTANT_ROULETTE = 'instantroulette',
  DUAL_PLAY = 'Dual Play',
  BLACKJACK = 'blackjack',
  POWER_BLACKJACK = 'powerscalableblackja',
  FREE_BET_BLACKJACK = 'freebet',
  INFINITE_BLACKJACK = 'scalableblackjack',
  LIGHTNING_BLACKJACK = 'lightningscalablebj',
  SICBO = 'sicbo',
  FANTAN = 'fantan',
  DRAGONTIGER = 'dragontiger',
  HOLDEM = 'holdem',
  DHP = 'dhp',
  CSP = 'csp',
  ETH = 'eth',
  THB = 'thb',
  SIDEBETCITY = 'sidebetcity',
  ANDARBAHAR = 'andarbahar',
  TEENPATTI = 'teenpatti',
  CRAZYTIME = 'crazytime',
  DEALNODEAL = 'dealnodeal',
  MEGABALL = 'megaball',
  MONOPOLY = 'monopoly',
  MONOPOLYBIGBALLER = 'monopolybigballer',
  LIGHTNINGDICE = 'lightningdice',
  CASHORCRASH = 'cashorcrash',
  CRAZYCOINFLIP = 'crazycoinflip',
  MONEYWHEEL = 'moneywheel',
  TOPCARD = 'topcard',
  GONZOTREASUREHUNT = 'gonzotreasurehunt',
  TOPDICE = 'topdice',
  CRAPS = 'craps',

  // simple play
  SP_NEW_YEAR_RICH = 'sp_new_year_rich',
  SP_THREE_STAR_GOD = 'sp_three_star_god',
  SP_THE_GUARD = 'sp_the_guard',
  SP_DRAGON_AND_TIGER = 'sp_dragon_and_tiger',
  SP_FANTASY_GODDESS = 'sp_fantasy_goddess',
  SP_JI_GONG = 'sp_ji_gong',
  SP_RED_CHAMBER = 'sp_red_chamber',
  SP_INNOCENT_CLASSMATES = 'sp_innocent_classmates',
  SP_FUNNY_FARM = 'sp_funny_farm',
  SP_RED_DRAGON = 'sp_red_dragon',
  SP_CREEPY_CUDDLERS = 'sp_creepy_cuddlers',
  SP_BIKINI_CHASER = 'sp_bikini_chaser',
  SP_DIAMOND_CRUSH = 'sp_diamond_crush',
  SP_FRUIT_POPPERS = 'sp_fruit_poppers',
  SP_TROPICAL_TREASURE = 'sp_tropical_treasure',
  SP_NORTH_SOUTH_LIONS = 'sp_north_south_lions',
  SP_CHEUNG_PO_TSAI = 'sp_cheung_po_tsai',
  SP_GOLDEN_CHICKEN = 'sp_golden_chicken',
  SP_FORTUNE_CAT = 'sp_fortune_cat',
  SP_SAINT_OF_MAHJONG = 'sp_saint_of_mahjong',
  SP_PROSPERITY_TREE = 'sp_prosperity_tree',
  SP_LUCKY_FA = 'sp_lucky_fa',
  SP_FORTUNE_LION = 'sp_fortune_lion',
  SP_CHILD_OF_WEALTH = 'sp_child_of_wealth',
  SP_SHANGHAI_GODFATHER = 'sp_shanghai_godfather',
  SP_DOUBLE_HAPPINESS = 'sp_double_happiness',
  SP_CAI_SHEN_DAO = 'sp_cai_shen_dao',
  SP_THREE_STAR_GOD_2 = 'sp_three_star_god_2',
  SP_WORLD_CUP_FEVER = 'sp_world_cup_fever',
  SP_THE_MASKED_PRINCE = 'sp_the_masked_prince',
  SP_PIGSY = 'sp_pigsy',
  SP_LUCKY_CLOVERS = 'sp_lucky_clovers',
  SP_MAYAS_MIRACLE = 'sp_mayas_miracle',
  SP_ZUES = 'sp_zues',
  SP_GOLD_OF_EGYPT = 'sp_gold_of_egypt',
  SP_SPARTAS_HONOR = 'sp_spartas_honor',
  SP_SIX_SWORDS = 'sp_six_swords',
  SP_DRAGON_SLAYER = 'sp_dragon_slayer',
  SP_MOTHER_OF_DRAGONS = 'sp_mother_of_dragons',
  SP_SHU_DYNASTY = 'sp_shu_dynasty',
  SP_MYSTICAL_LAMP = 'sp_mystical_lamp',
  SP_BOMBER_SQUAD = 'sp_bomber_squad',
  SP_THREE_MUSKETEERS = 'sp_three_musketeers',
  SP_VANESSA = 'sp_vanessa',
  SP_CHANG_THAI = 'sp_chang_thai',
  SP_CANDY_WITCH = 'sp_candy_witch',
  SP_MYTH_OF_PHOENIX = 'sp_myth_of_phoenix',
  SP_GUARDIAN_OF_FLAME = 'sp_guardian_of_flame',
  SP_GOLDEN_LEGACY = 'sp_golden_legacy',
  SP_WORLD_CUP_NIGHT = 'sp_world_cup_night',
  SP_SUPER_7 = 'sp_super_7',
  SP_DRAGON_8 = 'sp_dragon_8',
  SP_HOO_HEY_HOW = 'sp_hoo_hey_how',
  SP_BIG_THREE_DRAGONS = 'sp_big_three_dragons',
  SP_ANGELS_AND_DEMONS = 'sp_angels_and_demons',
  SP_VOLLEY_BEAUTIES = 'sp_volley_beauties',
  SP_ZOMBIE_HUNTER = 'sp_zombie_hunter',
  SP_CAGE_FIGHT = 'sp_cage_fight',
  SP_FISHERMEN_GOLD = 'sp_fishermen_gold',
  SP_LUSTROUS_OCEAN = 'sp_lustrous_ocean',
  SP_BUNNY_BEAUTY = 'sp_bunny_beauty',
  SP_BACCARAT = 'sp_baccarat',
  SP_COLOR_SICBO = 'sp_color_sicbo',
  SP_SHAKE_SHAKE = 'sp_shake_shake',
  SP_PULA_PUTI = 'sp_pula_puti',

  // Microgaming - slot
  SMG_SPINSPINSUGAR = 'SMG_spinSpinSugar',
  SMG_ROMESUPERMATCH = 'SMG_romeSupermatch',
  SMG_SUGARCRAZEBONANZA = 'SMG_sugarCrazeBonanza',
  SMG_ROMEFIGHTFORGOLD = 'SMG_romeFightForGold',
  SMG_FORTUNEPIKEGOLD = 'SMG_fortunePikeGold',
  SMG_BUBBLEBEEZ = 'SMG_bubbleBeez',
  SMG_CHILLIPEPEHOTSTACKS = 'SMG_chilliPepeHotStacks',
  SMG_MONKEYBONANZA = 'SMG_monkeyBonanza',
  SMG_GALLOGOLDMEGAWAYS = 'SMG_galloGoldMegaways',
  SMG_FIREANDROSESJOKER = 'SMG_fireAndRosesJoker',
  SMG_HAPPYLUCKYCATS = 'SMG_happyLuckyCats',
  SMG_CHESTSOFGOLD = 'SMG_chestsOfGold',
  SMG_WILDFIREWINSEXTREME = 'SMG_wildfireWinsExtreme',
  SMG_CANDYRUSHWILDS = 'SMG_candyRushWilds',
  SMG_WOLFBLAZEMEGAWAYS = 'SMG_wolfBlazeMegaways',
  SMG_GOLDBLITZ = 'SMG_goldBlitz',
  SMG_DRAGONSKEEP = 'SMG_dragonsKeep',
  SMG_DOKIDOKIFIREWORKS = 'SMG_dokiDokiFireworks',
  SMG_BISONMOON = 'SMG_bisonMoon',
  SMG_TROJANKINGDOM = 'SMG_trojanKingdom',
  SMG_FORTUNEDRAGON = 'SMG_fortuneDragon',
  SMG_MASTERSOFOLYMPUS = 'SMG_mastersOfOlympus',
  SMG_WWECLASHOFTHEWILDS = 'SMG_wweClashOfTheWilds',
  SMG_9MADHATS = 'SMG_9MadHats',
  SMG_PLAYBOYWILDS = 'SMG_playboyWilds',
  SMG_TIGERSICE = 'SMG_tigersIce',
  SMG_STARLITEFRUITSMEGAMOOLAH = 'SMG_starliteFruitsMegaMoolah',
  SMG_9SKULLSOFGOLD = 'SMG_9SkullsOfGold',

  // CFD
  CFD_TRADE_MARKET = 'cfd_trade_market',

  // BO
  BTC = 'btc',
  GOLD = 'gold',
  USDJPY = 'usdjpy',
  EURUSD = 'eurusd',
}
