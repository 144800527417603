// password rule copied from https://github.com/deanilvincent/check-password-strength
export const passwordRule = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s).{8,30}$/;

// https://emailregex.com/index.html
export const emailRule =
  // eslint-disable-next-line no-control-regex
  /^(?:[a-z0-9!#$%&'*/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

// https://emailregex.com/index.html but not allow +
export const emailNotAllowPlusRule =
  // eslint-disable-next-line no-control-regex
  /^(?:[a-z0-9!#$%&'*/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

export const usernameRule = /^[A-Za-z]\w{5,11}$/;

export const verificationCodeRule = /^[0-9]{6}$/;

export const aliasRegex = /^[A-Za-z-]{9,32}$/;

export const TRX_ADDRESS_REGEX = /^T[A-Za-z1-9]{33}$/;
export const ETH_ADDRESS_REGEX = /^0x[A-Fa-f\d]{40}$/;
export const L2_ADDRESS_REGEX = /^AS[A-Fa-f\d]{64}$/;
