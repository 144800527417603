export enum HnsRegisterStatus {
  RESERVED = 'RESERVED',
  AVAILABLE = 'AVAILABLE',
  REGISTERED = 'REGISTERED',
  SIMILAR_WORD = 'SIMILAR_WORD',
}

export enum HnsRequestStatus {
  REQUESTED = 'REQUESTED',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}
